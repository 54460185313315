<template>
  <div class="details-container">
    <div class="block-module">
      <div class="tab-header">
        <tabs-tmpe />
      </div>
    </div>
    <div class="block-module">
      <div class="page-title">{{insuranceData.name}}</div>
    </div>
    <div class="block-module">
      <div class="details-main">
        <div class="module-title">
          <div class="title-box">
            <h3 class="title-text">保险基本信息<span style="color: red;font-size: 12px;">&nbsp;&nbsp;&nbsp;（此保险均为户外机构作为投保人为参团人员安排的意外保险，不接受任何个人作为投保人的申请）</span></h3>
          </div>
          <div class="rest-box"></div>
        </div>
        <div class="module-container">
          <div class="form-container">
            <div class="form-module selected-box">
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">保障计划：</div>
                </div>
                <div class="fm-val">
                  <div class="tags-place">
                    <ul class="tp-list" v-if="insuranceData.loading">
                      <li class="tl-item" :class="{'active':insuranceData.current == index}"
                        v-for="(item,index) in insuranceData.plan" :key="index" @click="planChangeFun(index)">
                        {{item.name}}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">保障内容：</div>
                </div>
                <div class="fm-val">
                  <div class="swiper-palce" v-if="insuranceData.loading">
                    <swiper-temp :options="insuranceData.plan[insuranceData.current].safeguard" />
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">保障期限：</div>
                </div>
                <div class="fm-val">
                  <div class="tags-place">
                    <ul class="tp-list" v-if="insuranceData.loading">
                      <li class="tl-item" :class="{'active':insuranceData.current_days == index}"
                        v-for="(item,index) in insuranceData.plan[insuranceData.current].time_limit" :key="index"
                        @click="planDaysChangeFun(item,index)">
                        <span>{{item.min}}</span>
                        <span v-if="item.min != item.max">-{{item.max}}</span>
                        <span>天</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">起保日期：</div>
                </div>
                <div class="fm-val">
                  <div class="picker-date">
                    <div class="pd-item">
                      <el-date-picker type="date" v-model="insuranceData.confirmed_date_select" align="right"
                        :editable="false" :clearable="false" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd" :picker-options="insuranceData.config.date_picker_options"
                        @change="confirmedDateChangeFun">
                      </el-date-picker>
                    </div>
                    <!-- <div class="pd-item timer" v-if="insuranceData.config.to_day_pass"> -->
                    <div class="pd-item timer" v-if="insuranceData.time_format != 1">
                      <el-time-select v-model="insuranceData.confirmed_date_time_select" :clearable="false"
                        :readonly="!insuranceData.confirmed_date_select"
                        :picker-options="insuranceData.config.time_picker_options" placeholder="时间"
                        @change="confirmedDateTimeChangeFun">
                      </el-time-select>
                    </div>
                    <div class="pd-item end-timer">
                      <span class="unit">至</span>
                      <span>{{insuranceData.confirmed_date_end_data}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-module price-box">
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">保险金额：</div>
                </div>
                <div class="fm-val">
                  <div class="price-place">
                    <span class="price-unit">￥</span>
                    <span class="price-text"
                      v-if="insuranceData.loading">{{insuranceData.plan[insuranceData.current].time_limit[insuranceData.current_days].money}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-module">
      <div class="details-main applicant-info">
        <div class="module-title">
          <div class="title-box">
            <h3 class="title-text">投保人信息</h3>
            <el-tooltip effect="dark" content="" placement="top">
              <div slot="content">
                <p>只可开投保人抬头为公司名称的发票</p>
              </div>
              <span class="make-invoice-look">开票必看</span>
            </el-tooltip>
          </div>
          <div class="rest-box">
            <div class="add-applicant">
              <el-button class="fake-btn" @click="openAddApplicantDialogFun">
                <i class="iconfont icon-jiahao"></i>
                <span>新增投保人信息</span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="module-container">
          <div class="applicant-table">
            <el-checkbox-group v-model="applicantData.tableData.checkList"
              @change="applicantDataCheckboxGroupChangeFun">
              <el-table border row-class-name="fake-table-tr" :data="applicantData.tableData.list" style="width: 100%"
                max-height="728">
                <el-table-column label-class-name="fake-table-th" width="100px" align="center" prop="name" label="选择">
                  <template slot-scope="scope">
                    <!-- <el-checkbox class="checkbox-box" v-model="applicantData.tableData.checked"></el-checkbox> -->
                    <el-checkbox class="checkbox-box" :label="scope.$index"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column label-class-name="fake-table-th" width="150px" align="center" prop="name" label="名称">
                </el-table-column>
                <el-table-column label-class-name="fake-table-th" align="center" prop="certificate_type" label="证件类型">
                  <template slot-scope="scope">
                    <span v-if="scope.row.certificate_type == 1">身份证</span>
                    <span v-else>统一社会信用代码</span>
                  </template>
                </el-table-column>
                <el-table-column label-class-name="fake-table-th" align="center" prop="certificate_code" label="证件号码">
                </el-table-column>
                <el-table-column label-class-name="fake-table-th" align="center" prop="tel" label="手机号码">
                </el-table-column>
                <el-table-column label-class-name="fake-table-th" align="center" prop="email" label="电子邮箱">
                </el-table-column>
                <el-table-column label-class-name="fake-table-th" align="center" prop="control" label="操作">
                  <!-- <template slot-scope="scope">
                      <el-radio class="radio-box" v-model="applicantData.tableData.radio" :label="scope.$index">默认投保人</el-radio>
                    </template> -->
                  <template slot-scope="scope">
                    <div class="control-list">
                      <span class="control-item main-account" v-if="scope.row.is_parent == 1">主账号投保人不可操作</span>
                      <template v-else>
                        <span class="control-item control-changes" :data-data="scope.$index"
                          @click="reviseApplicantFun(scope.row)">修改</span>
                        <span class="control-item control-deletes" :data-data="scope.$index"
                          @click="delApplicantFun(scope.row,scope.$index)">删除</span>
                      </template>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div class="block-module">
      <div class="details-main recognizee-info">
        <div class="module-title">
          <div class="title-box">
            <h3 class="title-text">被保人信息</h3>
            <span
              class="descriptions">单次购买被保人最多{{insuranceData.buy_max_number}}人，如超过{{insuranceData.buy_max_number}}人请分批购买</span>
          </div>
        </div>
        <div class="module-container">
          <div class="operation-module">
            <ul class="operation-list">
              <li class="ol-item color-diff">
                <el-button class="fake-btn" @click="openPastePersonnelDialogFun">
                  <span>粘贴名单</span>
                </el-button>
              </li>
              <li class="ol-item">
                <!-- <el-button class="fake-btn">
                  <span>上传名单</span>
                </el-button> -->
                <div class="upload-module">
                  <el-upload class="upload-demo" ref="upload1" :action="ossBaseUrl" :limit="1"
                    :name="upload_option.name" :data="upload_option.param" :before-upload="handleBeforeUploadFun"
                    :on-remove="function(file, fileList){return handleRemoveFun(file, fileList,'upload1');}"
                    :on-change="function(file, fileList){ recognizeeData.upload.fileList = [];return handleChangeFun(file, fileList,'upload1');}"
                    :auto-upload="false" :file-list="recognizeeData.upload.fileList">
                    <el-button class="fake-btn" slot="trigger" size="small" type="primary">
                      <div class="btn-main">
                        <!-- <i class="iconfont icon-shangchuan"></i> -->
                        <span>上传名单</span>
                      </div>
                    </el-button>
                  </el-upload>
                </div>
              </li>
              <li class="ol-item">
                <el-button class="fake-btn" @click="downloadModelFun">
                  <span>模版下载</span>
                </el-button>
              </li>
              <li class="ol-item">
                <el-button class="fake-btn" @click="openaddPersonnelDialogFun">
                  <span>逐个添加</span>
                </el-button>
              </li>
            </ul>
            <ul class="operation-list">
              <li class="ol-item">
                <el-button class="fake-btn" @click="delPastePersonnelGatherFun">
                  <span>删除名单</span>
                </el-button>
              </li>
              <li class="ol-item">
                <el-button class="fake-btn" @click="exportErrorListFun">
                  <span>导出错误名单</span>
                </el-button>
              </li>
            </ul>
          </div>
          <div class="recognizee-table">
            <!-- <el-checkbox-group v-model="recognizeeData.tableData.checkList"> -->
            <el-table border :ref="recognizeeData.tableData.ref"
              :row-class-name="function(e) { return 'fake-table-tr ' + tableVerifyPersonnelFun(e)}"
              :data="recognizeeData.tableData.list" style="width: 100%" max-height="728"
              @selection-change="recognizeeDataSelectionChangeFun">
              <el-table-column label-class-name="fake-table-th" type="selection" align="center">
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" width="100px" align="center" prop="name" label="序号">
                <template slot-scope="scope">
                  <span v-if="(scope.$index + 1) < 10">00{{scope.$index + 1}} </span>
                  <span v-else-if="(scope.$index + 1) >= 10 && (scope.$index + 1) < 100">0{{scope.$index +1}} </span>
                  <span v-else>{{scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" width="120px" align="center" prop="name" label="名称">
                <template slot-scope="scope">
                  <template v-if="scope.row.error">
                    <el-tooltip effect="dark" content="" placement="top" v-if="scope.row.error.status == 'error'">
                      <div slot="content">
                        <!-- <p>{{scope.row.error.message}}</p> -->
                        <p v-for="(eitem,eindex) in scope.row.error_array" :key="eindex">{{eitem}}</p>
                      </div>
                      <span>
                        <span class="error-box"><i class="iconfont icon-wenhao-xianxingyuankuang"></i></span>
                      </span>
                    </el-tooltip>
                  </template>
                  <span>{{scope.row.name}}</span>
                </template>
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" align="center" prop="type" label="证件类型">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 1">身份证</span>
                  <span v-else-if="scope.row.type == 2">护照</span>
                  <span v-else-if="scope.row.type == 3">军官证</span>
                  <span v-else-if="scope.row.type == 4">港澳通行证</span>
                  <span v-else-if="scope.row.type == 5">台湾通行证</span>
                  <span v-else-if="scope.row.type == 6">其他</span>
                </template>
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" align="center" prop="code" label="证件号码">
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" width="100px" align="center" prop="sex" label="性别">
                <template slot-scope="scope">
                  <span v-if="scope.row.sex == 1">男</span>
                  <span v-else-if="scope.row.sex == 2">女</span>
                  <!-- <span v-else>未知</span> -->
                </template>
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" align="center" prop="birth" label="出生日期">
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" align="center" prop="tel" label="手机号码">
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" align="center" label="操作">
                <template slot-scope="scope">
                  <div class="control-list">
                    <span class="control-item control-changes" :data-data="scope.$index"
                      @click="revisePersonnelFun(scope.row,scope.$index)">修改</span>
                    <span class="control-item control-deletes" :data-data="scope.$index"
                      @click="delPersonnelFun(scope.row,scope.$index)">删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- </el-checkbox-group> -->
          </div>
        </div>
      </div>
    </div>

    <div class="block-module">
      <div class="details-main summary-info">
        <div class="module-container">
          <div class="summary-module">
            <ul class="sm-list">
              <li class="sl-item">
                <span class="sl-key">生效日期：</span>
                <span class="sl-val">
                  <span>{{ insuranceData.confirmed_date_start_data }} 至 {{insuranceData.confirmed_date_end_data}}</span>
                </span>
              </li>
            </ul>
          </div>
          <div class="summary-module">
            <ul class="sm-list">
              <li class="sl-item">
                <span class="sl-key">平均保费：</span>
                <span class="sl-val">
                  <span
                    v-if="(orderData.price.mean_price && recognizeeData.tableData.checkList.length > 0)">{{orderData.price.mean_price}}元</span>
                  <span v-else>--</span>
                </span>
              </li>
              <li class="sl-item">
                <span class="sl-key">总人数：</span>
                <span class="sl-val">
                  <span
                    v-if="(orderData.people_number && recognizeeData.tableData.checkList.length > 0)">{{orderData.people_number}}人</span>
                  <span v-else>--</span>
                </span>
              </li>
              <li class="sl-item">
                <span class="sl-key">总保费：</span>
                <span class="sl-val">
                  <span v-if="orderData.price.total_price">{{orderData.price.total_price}}元</span>
                  <span v-else>--</span>
                </span>
              </li>
            </ul>
            <ul class="sl-control">
              <li class="control-item save-btn">
                <el-button class="fake-btn" @click="policyApplicationClickFun(2)">
                  <span>保存订单</span>
                </el-button>
              </li>
              <li class="control-item submit-btn">
                <el-button class="fake-btn" @click="policyApplicationClickFun">
                  <span>提交订单</span>
                </el-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="block-module" v-if="insuranceData.loading && insuranceData.agreement.length > 0">
      <div class="details-main server-writ">
        <div class="module-container">
          <div class="sw-module">
            <el-collapse v-model="activeNames" @change="handleChange">
              <template v-for="(item,index) in insuranceData.agreement">
                <el-collapse-item :title="item.name" :name="index" :key="index">
                  <div v-html="item.content"></div>
                </el-collapse-item>
              </template>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗-添加投保人 -->
    <add-applicant-dialog :ref="applicantData.addParam.ref" :options="applicantData.addParam.options"
      :visible="applicantData.addParam.visible" @on-change="addApplicantDialogChangeFun" />

    <!-- 弹窗-添加人员 -->
    <add-personnel-dialog :ref="recognizeeData.addParam.ref" :cert-type="insuranceData.cert_type"
      :options="recognizeeData.addParam.options" :visible="recognizeeData.addParam.visible"
      :type="recognizeeData.addParam.index || parseInt(recognizeeData.addParam.index) == parseInt(0) ? 2 : 1"
      @on-change="addPersonnelDialogChangeFun" />

    <!-- 弹窗-粘贴名单 -->
    <paste-personnel-dialog :ref="recognizeeData.pasteParam.ref" :cert-type="insuranceData.cert_type"
      :visible="recognizeeData.pasteParam.visible" @on-change="pastePersonnelDialogChangeFun" />

    <!-- 对话框 -->
    <my-dialog :visible="myDialog.visible" :options="myDialog.options" @on-change="myDialogChangeFun" />

    <!-- 支付弹窗 -->
    <my-pay-dialog :visible="pay_config.visible" :payee="pay_config.payee" :price="pay_config.price"
      :qr-code="pay_config.qr_code" :pay-mode="pay_config.select.pay_mode" @on-change="payResultChangeFun" />

    <!-- 选址支付方式 -->
    <select-pay-method :type="2" :visible="pay_config.select.visible" :options="{price: orderData.price.total_price}"
      @on-change="choosePayResultChangeFun" />

  </div>
</template>

<script>
  import {
    deepClone, // 深拷贝
    analysisDateObject, // 解析日期对象
    timestampToTime, // 将时间戳转换成日期格式
    dataURLToBlob, // 将base64转换为blob
    blobToFile, // 将blob转换为file
    blobToImage, // 将Blob转image
    imageToBlob, // image转Blob
    fileToBase64, // file转base64
    base64ToFile, // base64转file
  } from '@/utils/utils_fun.js';
  import NP from '@/utils/number-precision'
  import tabsTmpe from '@/views/insurance/components/tabs-temp/tabs-temp.vue';
  import swiperTemp from '@/views/insurance/components/swiper-temp/SwiperTepm.vue'
  import addApplicantDialog from '@/views/insurance/child-pages/insurance-details/template/add-applicant-dialog.vue'
  import addPersonnelDialog from '@/views/insurance/child-pages/insurance-details/template/add-personnel-dialog.vue'
  import pastePersonnelDialog from '@/views/insurance/child-pages/insurance-details/template/paste-personnel-dialog.vue'
  export default {
    // 允许组件模板递归地调用自身
    name: 'InsuranceDetails',
    // 声明一组可用于组件实例中的组件
    components: {
      tabsTmpe,
      swiperTemp,
      addApplicantDialog,
      addPersonnelDialog,
      pastePersonnelDialog,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        ossBaseUrl: this.GLOBAL.ossBaseUrl,
        page_id: '', // 页面id
        page_message_obj: null,
        again_order: { // 再来一单的数据
          loading: false,
          resData: {}, // 响应数据
          param: {
            id: '',
          }
        },
        // 上传文件配置选项
        upload_option: {
          url: '',
          name: 'file',
          param: {
            key: '',
            policy: '',
            OSSAccessKeyId: '',
            signature: '',
          },
          record_info: {
            name: '',
            url: '',
          }
        },
        formData: {},
        // map_insuranceData_confirmed_date_select: '', // 映射起保日期-选择 (原因不可多层调用)
        insuranceData: { // 保险数据
          loading: false, // 是否装载完成
          current: 0, // 当前计划
          current_days: 0, // 当前天数
          confirmed_date_select: '', // 起保日期-选择
          confirmed_date_time_select: '', // 起保日期-时间-选择
          confirmed_date_start_data: '', // 起保日期
          confirmed_date_end_data: '', // 截止日期
          config: {
            date_init_loading: true, // 是否是第一次初始时间
            to_day_pass: false, // 当天是否可以通过
            data_picker_limit_time: '', // 日期限制：毫秒数
            date_picker_options: { // 日期限制
              disabledDate(time) {
                // let hours_limit = (1000 * 60 * 60 * 2); // 2小时
                let limit = (1000 * 60 * 60 * 24) * 1; // 1天
                // 选择总时间(如: 2021-11-25 00:00:00) - 2小时(00:00:00 - 02:00 = 2021-11-24 22:00:00) 小于 当前总时间(如: 2021-11-25 00:00:00) - 1天(2021-11-25 00:00:00 - 24:00:00 = 2021-11-24 00:00:00 ) 返回 false
                // return (time.getTime() - hours_limit) < (Date.now() - limit);
                // return time.getTime() < Date.now(); // 返回不能买当天
                return time.getTime() < (Date.now() - limit); // 返回能买当天
              },
            },
            time_picker_options: { // 时间限制
              start: '00:00',
              step: '00:05',
              end: '23:55'
            }
          }
        },
        applicantData: { // 投保人数据
          loading: false, // 是否装载完成
          addParam: { // 添加投保人参数
            ref: 'add-applicant-dialog',
            visible: false,
            options: {},
          },
          requestConfig: { // 请求配置
            data: {
              page: 1,
              page_size: 10
            }
          },
          resData: {}, // 响应数据
          tableData: {
            radio: '',
            checked: 0,
            checkList: [],
            list: []
          }
        },
        recognizeeData: { // 被保人数据
          cache: { // 缓存
            name: 'recognizeeData',
          },
          addParam: { // 添加投保人参数
            ref: 'add-recognizee-dialog',
            visible: false,
            options: {},
            index: '',
          },
          pasteParam: { // 被保人粘贴名单参数
            ref: 'paste-personnel-dialog',
            visible: false,
          },
          upload: { // 上传文件
            fileList: []
          },
          tableData: {
            ref: 'recognizeeMultipleTable',
            radio: '',
            checked: 0,
            checkList: [],
            list: []
          }
        },
        activeNames: [],
        myDialog: {
          data: {},
          visible: false,
          options: {},
        },
        pay_config: { // 支付配置
          select: {
            metadata: {},
            visible: false,
            value: '',
            pay_mode: '',
            is_parent_pay: '',
          },
          visible: false,
          pay_timer: null,
          payee: '',
          price: '',
          qr_code: '',
        },
        orderData: { // 订单数据
          people_number: '', // 人数
          price: { // 价格
            mean_price: '',
            total_price: '',
          }
        }
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      handleChange(val) {
        console.log(val);
      },
      /**
       * 设置缓存
       */
      setCachedFun(key, value) {
        value = value || [];
        // mySetStorage, // 设置本地缓存
        // myGetStorage, // 获取本地缓存
        // myRemoveStorage, // 删除本地缓存
        console.log('设置缓存 key == ', key)
        console.log('设置缓存 value == ', value)
        this.mySetStorage({
          name: key,
          value: value,
          expires: "",
          // startTime: new Date().getTime()//记录何时将值存入缓存，毫秒级
        })
        // 存储输入历史
        this.mySetStorageInputHistory({
          name: this.STORAGE.input_history.child_name['recognizee'],
          value: value,
        });
        // 获取缓存
        let data = this.getCachedFun(key);
        console.log('设置缓存后马上获取缓存 data == ', data)
      },
      /**
       * 获取缓存
       */
      getCachedFun(key) {
        console.log('获取缓存 key == ', key)
        let data = this.myGetStorage(key);
        data = data || [];
        console.log('获取缓存 data == ', data)
        // 存储输入历史
        this.mySetStorageInputHistory({
          name: this.STORAGE.input_history.child_name['recognizee'],
          value: data,
        });
        return data;
      },
      /**
       * 获取页面数据
       */
      getPageDataFun() {

        // 获取页面路由参数
        this.getPageRouteParamFun().then((res) => {
          let {
            id,
            order_id
          } = res;
          console.log('获取页面路由参数 id == ', id)
          console.log('获取页面路由参数 order_id == ', order_id)
          if (order_id) {
            // 获取再来一单的数据
            this.getAgainOrderDataFun(order_id).then((res) => {
              // 获取保险数据列表
              this.getInsuranceDataFun(id).then((res) => {


                // 获取投保人数据
                this.getApplicantDataFun().then((res) => {
                  // 初始化再来一单选项
                  this.initAgainOrderOptionsFun();
                });
                // 初始化被保人数据
                this.initPersonnelDataFun();

              });
            });
          } else {
            // 获取保险数据列表
            this.getInsuranceDataFun(id).then(() => {

              // 获取投保人数据
              this.getApplicantDataFun();
              // 初始化被保人数据
              this.initPersonnelDataFun();

            });
          }

        })
      },
      /**
       * 初始化当天是否可选
       */
      initToDayIsCanSelectFun(flag) {
        console.log('初始化当天是否可选 flag == ', flag)
        let date_picker_options = { // 日期限制
          disabledDate(time) {
            let limit = (1000 * 60 * 60 * 24) * 1; // 1天
            if (flag) {
              return time.getTime() < (Date.now() - limit); // 返回能买当天
            } else {
              return time.getTime() < Date.now(); // 返回不能买当天
            }
          },
        };

        this.insuranceData.config.date_picker_options = date_picker_options;
      },
      /**
       * 获取页面路由参数
       */
      getPageRouteParamFun() {
        return new Promise((resolve, reject) => {
          // 获取当前路由
          this.myGetRouter().then((res) => {
            console.log('获取页面路由参数 res ==', res)
            let {
              id,
              order_id,
              type
            } = res.query;
            this.page_id = id;
            if (type != 2) {
              this.again_order.param.id = order_id;
            }
            console.log('获取页面路由参数 this.again_order ==', this.again_order)
            if (id) {
              console.log('获取页面路由参数 order_id ==', order_id)
              resolve(res.query);
            } else {
              reject();
            }
          });
        })
      },
      /**
       * 获取再来一单的数据
       */
      getAgainOrderDataFun(id) {
        return new Promise((resolve, reject) => {
          this.myRequest({
              url: '/api/insurance/insuredorderdetail',
              data: {
                id: id,
              }
            })
            .then((res) => {
              console.log('获取再来一单的数据 res == ', res)
              let resData = res.data.data;
              this.again_order.resData = Object.assign(this.again_order.resData, resData);
              this.again_order.loading = true;
              console.log('获取再来一单的数据 this.again_order == ', this.again_order)
              resolve(resData);
            }).catch((err) => {
              console.log('获取再来一单的数据 err == ', err)
            })
        })
      },
      /**
       * 初始化再来一单选项
       */
      initAgainOrderOptionsFun(prev, next) {
        const _this = this;
        prev = prev || this.again_order.resData; // 上一单数据
        next = next || this.insuranceData; // 下一单数据（即：即将下单的数据）
        let {
          to_day_pass
        } = next.config;

        console.log('prev == ', prev)
        console.log('next == ', next)

        // ------------------  初始化保险数据 ------------------ //
        let initPlan = next.plan.findIndex(item => item.id == prev.plan.id); // 初始化-计划
        let initDay; // 初始化-天
        if (initPlan != -1) {
          initDay = next.plan[initPlan].time_limit.findIndex(item => item.max == prev.guarantee_day);
        }
        console.log('initPlan == ', initPlan)
        console.log('initDay == ', initDay)
        // 初始化-保险数据
        let init_insuranceData = {
          current: initPlan, // 选择计划
          current_days: initDay, // 选择天数
        }
        // 更新-保险数据
        this.insuranceData = Object.assign(this.insuranceData, init_insuranceData);
        this.initAndlimitDateTimeFun();

        /* --- 上一单的起保时间 start -- */
        let {
          guarantee_start_time_time,
          guarantee_end_time_time
        } = prev;
        let again_date = analysisDateObject(guarantee_start_time_time, () => {}, true);
        console.log('again_date == ', again_date)
        let {
          nowaday,
          target
        } = again_date;
        let again_limit = this.insuranceData.confirmed_date_time_select; // 选中时间
        let again_limit_str = `${nowaday.year}/${nowaday.month}/${nowaday.days} ${again_limit}:00`;
        let again_limit_date = analysisDateObject(again_limit_str, () => {}, true);
        console.log('again_limit_date == ', again_limit_date)
        console.log('target.time == ', target.time)
        console.log('again_limit_date.time == ', again_limit_date.target.time)
        console.log('111) == ', (Number(again_limit_date.target.time)))
        console.log('flag == ', target.time > ((Number(again_limit_date.target.time)) + (1000 * 60 * 5)))
        // 选中时间是否大于当前时间
        if (target.time > (Number(nowaday.time) + (1000 * 60 * 5))) { //(Number(again_limit_date.target.time)
          console.log('选中时间是否大于当前时间 == ')
          this.insuranceData.confirmed_date_select = `${target.year}-${target.month}-${target.days}`; // 选中日期
          // this.insuranceData.config.time_picker_options.start = ; // 限制时间
          this.insuranceData.confirmed_date_time_select = `${target.hours}:${target.minutes}`; // 选中时间
          // 如果是当天-取反
          if (!((Number(nowaday.year) == Number(target.year) && Number(nowaday.month) == Number(target.month) && Number(
              nowaday.days) == Number(target.days)))) {
            this.insuranceData.config.time_picker_options.start = '00:00'; // 限制时间
          }
        } else {
          let next_day_timestamp = Number(nowaday.time) + ((1000 * 60 * 60 * 24) * 1);
          let next_day_date = analysisDateObject(next_day_timestamp, () => {}, true);
          console.log('next_day_date == ', next_day_date)
          this.insuranceData.confirmed_date_select =
            `${next_day_date.target.year}-${next_day_date.target.month}-${next_day_date.target.days}`; // 选中日期
          this.insuranceData.confirmed_date_time_select = '00:00'; // 选中时间
          this.insuranceData.config.time_picker_options.start = '00:00'; // 限制时间
        }
        // 初始化结束日期
        this.initEndDateFun();
        /* --- 上一单的起保时间 end -- */
        // ------------------  初始化保险数据 end ------------------ //


        // ------------------  初始化投保人数据 ------------------ //
        console.log('this.applicantData == ', this.applicantData)
        let {
          person
        } = prev; // 上一投保人数据
        console.log('上一投保人数据 person == ', person)
        let {
          tableData
        } = this.applicantData; // 当前投保人数据
        let applicant_index = tableData.list.findIndex(item => item.certificate_code == person.certificate_code);
        console.log('上一投保人数据 applicant_index == ', applicant_index)
        if (applicant_index != -1) {
          // 更新投保人数据
          this.applicantData.tableData.checkList = [applicant_index];
        } else {
          let ref_name = this.applicantData.addParam.ref;
          // 快捷-添加投保人
          this.$refs[ref_name]._shortcutSendEnteringAfterFun(person, {
            is_rectify: true
          }).then(res => {
            // 更新投保人数据
            this.applicantData.tableData.list.unshift(person);
            this.applicantData.tableData.checkList = [0];
            this.applicantData.tableData.radio = 0;
          })
        }
        // ------------------  初始化投保人数据 end ------------------ //

        // ------------------  初始化被保人数据 ------------------ //
        console.log('this.recognizeeData == ', this.recognizeeData)
        let {
          insured
        } = prev; // 上一被保人数据
        let {
          tableData: recgnizee_tableData
        } = this.recognizeeData; // 当前被保人数据
        recgnizee_tableData.list = []; // 清空
        console.log('上一被保人数据 insured == ', insured)
        // 删除-被保人-本地缓存
        this.myRemoveStorage(this.recognizeeData.cache.name);
        // 快捷-添加被保人函数
        this.fastAddrecognizeeDataFun(insured).then((res) => {
          console.log('快捷-添加 res == ', res)
          // 手动选择-被保人数据
          this.selectManuallyRecognizeeData(res);
        });
        // -----------------------------------------------------------------------------------
        // let recognizee_checkList = []; // 存在的
        // let rest_checkList = []; // 不存在的
        // insured.forEach((item => {
        //   let name = item.name;
        //   // 查找包含的
        //   let recognizee_item = recgnizee_tableData.list.find(ritem => ritem.name == name);
        //   if(recognizee_item) {
        //     recognizee_checkList.push(recognizee_item);
        //   }

        //   // 查找不包含的
        //   let is_exist = true;
        //   try {
        //     recgnizee_tableData.list.forEach(citem => {
        //       let cName = citem.name;
        //       if(!is_exist) new Error('跳出循环');
        //       if( name == cName ) {
        //         is_exist = false;
        //       }
        //     })
        //     if(is_exist) {
        //       rest_checkList.push(item)
        //     }
        //   } catch(err) {
        //     console.log(err)
        //   }
        // }))
        // console.log('recognizee_checkList == ',recognizee_checkList)
        // console.log('rest_checkList == ',rest_checkList)

        // // 如果还有被保人数据，则全部选上
        // if(recognizee_checkList.length == insured.length) {
        //   // 手动选择-被保人数据
        //   this.selectManuallyRecognizeeData(recognizee_checkList);
        // } else { // 缺了就新增

        // let ref_name = this.recognizeeData.addParam.ref;
        // // 快捷-添加投保人
        // this.$refs[ref_name]._shortcutSendEnteringAfterFun(rest_checkList).then(res => {
        //   // 更新被保人数据-添加人员
        //   this.personnelAddFun(false,res);
        //   // 初始化被保人数据
        //   this.initPersonnelDataFun().then((res) => {
        //     let result = [];
        //     // 查找包含的
        //     insured.forEach((item) => {
        //       let result_item = res.find(ritem => ritem.name == item.name);
        //       if(result_item) {
        //         result.push(result_item);
        //       }
        //     })
        //     console.log('查找包含的 result == ',result)
        //     // 手动选择-被保人数据
        //     this.selectManuallyRecognizeeData(result);
        //   });
        // })

        // // 快捷-添加被保人函数
        // this.fastAddrecognizeeDataFun(rest_checkList).then((res) => {
        //   let result = [];
        //   // 查找包含的
        //   insured.forEach((item) => {
        //     let result_item = res.find(ritem => ritem.name == item.name);
        //     if(result_item) {
        //       result.push(result_item);
        //     }
        //   })
        //   console.log('查找包含的 result == ',result)
        //   // 手动选择-被保人数据
        //   this.selectManuallyRecognizeeData(result);
        // });
        // }
        //-----------------------------------------------------------------------------------
        // ------------------  初始化被保人数据 end ------------------ //
      },
      /**
       * 快捷-添加被保人函数
       */
      fastAddrecognizeeDataFun(form) {
        console.log('快捷-添加被保人函数 form == ', form)
        let ref_name = this.recognizeeData.addParam.ref;
        return new Promise((resolve, reject) => {
          // 快捷-添加投保人
          this.$refs[ref_name]._shortcutSendEnteringAfterFun(form, {
            is_rectify: true
          }).then(res => {
            console.log('快捷-添加被保人函数 res == ', res)
            // 更新被保人数据-添加人员
            this.personnelAddFun(false, res);
            // 初始化被保人数据
            this.initPersonnelDataFun().then((res) => {
              resolve(res);
            });
          })
        })
      },
      /**
       * 手动选择-被保人数据
       */
      selectManuallyRecognizeeData(arr) {
        let ref_name = this.recognizeeData.tableData.ref;
        if (arr) {
          arr.forEach(item => {
            this.$refs[ref_name].toggleRowSelection(item);
          });
        }
      },
      /**
       * 获取保险数据
       */
      getInsuranceDataFun(id) {
        return new Promise((resolve, reject) => {
          this.myRequest({
              url: '/api/insurance/detail',
              data: {
                id: id,
              }
            })
            .then((res) => {
              console.log('获取保险数据 res == ', res)
              let resData = res.data.data;
              this.insuranceData = Object.assign(this.insuranceData, resData);
              let to_day_pass = (resData.allow_today_buy == 1); // 保险 - 是否允许当天投保【0否，1是】
              this.insuranceData.config.to_day_pass = to_day_pass;
              this.insuranceData.loading = true;


              console.log('保险新数据 this.insuranceData == ', this.insuranceData)
              resolve(resData);
              // 初始化当天是否可选
              this.initToDayIsCanSelectFun(to_day_pass)
              // 初始化与日期时间限制
              this.initAndlimitDateTimeFun();
            }).catch((err) => {
              console.log('获取保险数据 err == ', err)
            })
        })
      },
      /**
       * 获取投保人数据
       */
      getApplicantDataFun() {
        return new Promise((resolve, reject) => {
          let {
            requestConfig
          } = this.applicantData;
          this.myRequest({
              url: '/api/insurance/insuredlist',
              // data: {
              //   page: '', // 否	string	分页码 默认值：1
              //   page_size: '', // 否	string	每页显示条数 默认值：10
              // }
              data: requestConfig.data,
            })
            .then((res) => {
              console.log('获取投保人数据 res == ', res)
              let resData = res.data.data;
              this.applicantData.resData = resData
              this.applicantData.tableData.list = resData.list;
              // 存储输入历史
              this.mySetStorageInputHistory({
                name: this.STORAGE.input_history.child_name['applicant'],
                value: resData.list,
              });
              let findRadio = resData.list.findIndex(item => item.is_default == 1);
              console.log('获取投保人数据 findRadio == ', findRadio)
              if (findRadio != -1) {
                this.applicantData.tableData.radio = findRadio;
                this.applicantData.tableData.checkList = [findRadio];
              } else {
                this.applicantData.tableData.radio = '';
                this.applicantData.tableData.checkList = [];
              }
              this.applicantData.loading = true;
              console.log('获取投保人数据 this.applicantData == ', this.applicantData)
              resolve(res);
            }).catch((err) => {
              console.log('获取投保人数据 err == ', err)
            })

        })
      },
      /**
       * 计划变换
       */
      planChangeFun(index) {
        this.insuranceData.current = index;
        // 获取价格数据
        this.getPriceDataFun();
      },
      /**
       * 计划天数数变换
       */
      planDaysChangeFun(item = {}, index) {
        let {
          min,
          max
        } = item;
        console.log('min == ', min)
        console.log('max == ', max)
        console.log('index == ', index)
        this.insuranceData.current_days = index;
        // 初始化结束日期
        this.initEndDateFun();
      },
      /**
       * 起保日期变换
       */
      confirmedDateChangeFun(e) {
        console.log('起保日期变换 e == ', e)
        if (e) {
          this.map_insuranceData_confirmed_date_select = e;
          let str = e + ' ' + this.insuranceData.confirmed_date_time_select;
          console.log('起保日期变换 str == ', str)
          let date = analysisDateObject(str, () => {}, true);
          console.log('起保日期变换 date == ', date)
          // 初始化与日期时间限制
          this.initAndlimitDateTimeFun(date);
        } else {
          this.insuranceData.confirmed_date_time_select = ''; // 选中时间
        }
        // 初始化结束日期
        this.initEndDateFun();
      },
      /**
       * 起保日期-时间-变换
       */
      confirmedDateTimeChangeFun(e) {
        console.log('起保日期-时间-变换 e == ', e)
        this.insuranceData.confirmed_date_time_select = e; // 选中时间
        if (e) {
          // 初始化结束日期
          this.initEndDateFun();
        }
      },
      /**
       * 初始化与日期时间限制
       */
      initAndlimitDateTimeFun(date) {
        // console.log('================================== ');
        date = date || analysisDateObject('', () => {}, true);
        let {
          config
        } = this.insuranceData;
        let {
          date_init_loading,
          to_day_pass
        } = config;
        console.log('初始化与日期时间限制 date == ', date)
        console.log('初始化与日期时间限制 date_init_loading == ', date_init_loading)
        let {
          nowaday,
          target
        } = date;
        // let time = '00:00:00';
        let time = '00:00';
        let half_hour = Math.ceil((Number(nowaday.minutes) + (10 + 1)) / 5) * 5;
        let halfhourFlag = 60 - Number(nowaday.minutes) <= 15; // 当前小时剩下最后 5 分钟
        // let textMinutes = 45;
        // let half_hour = Math.ceil((Number(textMinutes) + (10 + 1)) / 5) * 5;
        // let halfhourFlag = 60 - Number(textMinutes) <= 15; // 当前小时剩下最后 5 分钟
        half_hour = this.limitedTimeDisplayFun(half_hour); // 限制时间显示

        if (!date_init_loading) {
          // 如果选择当天
          if (Number(nowaday.year) == Number(target.year) && Number(nowaday.month) == Number(target.month) && Number(
              nowaday.days) == Number(target.days)) {
            let current_hours = Number(nowaday.hours); // 当前小时
            let claculate_hours = Number(nowaday.hours) + 1; // 下一小时
            if (claculate_hours > 23) {
              claculate_hours = '00';
            }
            console.log('current_hours == ', current_hours);
            console.log('claculate_hours == ', claculate_hours);
            console.log('(current_hours >= 23 &&  halfhourFlag) || (claculate_hours > 23 && halfhourFlag) == ', (
              current_hours >= 23 && halfhourFlag) || (claculate_hours > 23 && halfhourFlag));
            time = `${claculate_hours}:00`;
            // if((current_hours >= 23 &&  halfhourFlag) || (claculate_hours > 23 && halfhourFlag)) { // 选择晚上23:的，则跳到第二天
            if ((current_hours >= 23 && halfhourFlag) || (claculate_hours > 23 && halfhourFlag)) { // 选择晚上23:的，则跳到第二天
              let start_timestamp = Number(target.time) + ((1000 * 60 * 60 * 24) * 1);
              let new_start_date = analysisDateObject(start_timestamp, () => {}, true);
              console.log('初始化与日期时间限制 new_start_date == ', new_start_date)
              target = new_start_date.target;
              this.myMessage({
                message: '购买当天的保险需要在当天的 23点55分 前购买。',
              })
            } else {
              // time = `${current_hours}:${half_hour}`;
              // // console.log('初始化与日期时间限制 60 - Number(nowaday.minutes) == ',60 - Number(nowaday.minutes))
              // if (halfhourFlag) {
              //   time = `${claculate_hours}:${half_hour}`;
              // }
              time = `${current_hours}:${half_hour}`;
              // console.log('初始化与日期时间限制 60 - Number(nowaday.minutes) == ',60 - Number(nowaday.minutes))
              console.log('初始化与日期时间限制 time1 == ', time)
              if (halfhourFlag) {
                time = `${claculate_hours}:${half_hour}`;
                console.log('初始化与日期时间限制 time2 == ', time)
              }
            }
          }
        } else { // 初始页面选项为下一天
          if (date_init_loading) this.insuranceData.config.date_init_loading = false;
          let next_day_timestamp = Number(nowaday.time) + ((1000 * 60 * 60 * 24) * 1);
          let next_day_date = analysisDateObject(next_day_timestamp, () => {}, true);
          console.log('next_day_date == ', next_day_date)
          target = next_day_date.target;
        }
        console.log('time == ', time);
        console.log('初始化与日期时间限制 target == ', target)
        let select_date = `${target.year}-${target.month}-${target.days}`;
        // this.insuranceData.confirmed_date_start_data = select_date + ' ' + time, // 起保日期
        this.insuranceData.confirmed_date_select = select_date; // 选中日期
        // this.map_insuranceData_confirmed_date_select = select_date; // 选中日期
        this.insuranceData.config.time_picker_options.start = time; // 限制时间
        this.insuranceData.confirmed_date_time_select = time; // 选中时间
        // console.log('==================================');
        // 初始化结束日期
        this.initEndDateFun();
      },
      /**
       * 初始化结束日期
       */
      initEndDateFun() {

        let {
          current,
          current_days,
          plan,
          confirmed_date_select,
          confirmed_date_time_select,
          config,
          time_format
        } = this.insuranceData;
        let {
          date_init_loading,
          to_day_pass
        } = config;
        let tPlan = plan[current];

        // 日期
        // let start_date = analysisDateObject(start_str,()=>{},true);
        let tDay = Number(tPlan.time_limit[current_days].max - 1);
        console.log('初始化结束日期 tDay == ', tDay)
        console.log('初始化结束日期 confirmed_date_select == ', confirmed_date_select)
        console.log('初始化结束日期 confirmed_date_time_select == ', confirmed_date_time_select)
        let start_str = confirmed_date_select + ' ' + confirmed_date_time_select;
        console.log('初始化结束日期 start_str == ', start_str)
        let start_date = analysisDateObject(start_str, () => {}, true);
        console.log('start_date == ', start_date)
        let start_time = start_date.target.time;
        let start_result = `${start_date.target.year}-${start_date.target.month}-${start_date.target.days}`;
        console.log('start_time == ', start_time)
        // 如果是当天，则进一
        // if(date_init_loading || ((start_date.nowaday.days == start_date.target.days && !to_day_pass)) || ((start_date.target.hours >= 23 && 60 - Number(start_date.target.minutes) <= 30))) {
        // if((start_date.nowaday.days == start_date.target.days) && !to_day_pass && (start_date.target.hours >= 23 && 60 - Number(start_date.target.minutes) <= 30)) {
        let is_toDay_flag = (start_date.nowaday.year == start_date.target.year && start_date.nowaday.month == start_date
          .target.month && start_date.nowaday.days == start_date.target.days);
        if (is_toDay_flag && time_format == 0) {
          let start_timestamp = Number(start_time) + ((1000 * 60 * 60 * 24) * 1);
          let new_start_date = analysisDateObject(start_timestamp, () => {}, true);
          console.log('new_start_date == ', new_start_date)
          start_time = new_start_date.target.time;
          // if(date_init_loading) {
          // start_result = `${new_start_date.target.year}-${new_start_date.target.month}-${new_start_date.target.days}`; // 下一天
          // } else {
          start_result =
            `${new_start_date.nowaday.year}-${new_start_date.nowaday.month}-${new_start_date.nowaday.days}`; // 当天
          // }
        }
        let end_time = ((1000 * 60 * 60 * 24) * Number(tDay)) - 1;
        let end_timestamp = Number(start_time) + Number(end_time);
        console.log('end_timestamp == ', end_timestamp)
        let end_date = analysisDateObject(end_timestamp, () => {}, true);
        console.log('end_date == ', end_date)
        let {
          nowaday,
          target
        } = end_date;
        if (!is_toDay_flag) {
          let to_next_end_date = analysisDateObject(end_timestamp + ((1000 * 60 * 60 * 24) * 1), () => {}, true); // 后天
          console.log('to_next_end_date == ', to_next_end_date)
          target = to_next_end_date.target;
        }
        console.log('start_result == ', start_result)
        let full_start_result = start_result + ' ' + confirmed_date_time_select + ':00';
        let end_result =
          `${target.year}-${target.month}-${target.days} ${target.hours}:${target.minutes}:${target.seconds}`;
        if (time_format == 1) {
          full_start_result = start_result + ' ' + '00:00:00';
          end_result = `${target.year}-${target.month}-${target.days} 23:59:59`;
        }


        console.log('end_result == ', end_result)
        // this.insuranceData.confirmed_date_select = start_result; // 选中日期
        // this.map_insuranceData_confirmed_date_select = start_result; // 选中日期
        // this.insuranceData.confirmed_date_start_data = start_result + ' 00:00:00'; // 开始日期

        this.insuranceData.confirmed_date_start_data = full_start_result; // 开始日期
        this.insuranceData.confirmed_date_end_data = end_result; // 截止日期
        console.log('this.insuranceData == ', this.insuranceData);

        // 获取价格数据
        this.getPriceDataFun();
      },
      /**
       *  限制时间显示
       */
      limitedTimeDisplayFun(time) {
        let result = time;
        if (time >= 60 && time < 65) {
          result = '00';
        } else if (time >= 65 && time < 70) {
          result = '05';
        } else if (time >= 70 && time < 75) {
          result = '10';
        } else if (time >= 75) {
          result = '15';
        }
        return result;
      },
      /**
       * 打开-添加投保人-弹窗
       */
      openAddApplicantDialogFun() {
        this.applicantData.addParam.visible = true; // 投保人数据
      },
      /**
       * 编辑-投保人
       */
      reviseApplicantFun(item) {
        console.log('编辑-投保人 item == ', item)
        let copy_data = deepClone(item); // 深拷贝
        this.applicantData.addParam.options = copy_data; // 编辑数据
        // 打开-添加投保人-弹窗
        this.openAddApplicantDialogFun();
      },
      /**
       * 删除-投保人
       */
      delApplicantFun(item, index) {
        console.log('删除-投保人 item == ', item)
        this.myDialog = {
          data: item,
          visible: true,
          options: {
            title: '删除',
            message: `确认要删除名为[${item.name}]的数据？`,
            is_tips: false,
            sign: 'applicant',
          },
        }
      },
      /**
       * 删除-投保人-请求
       */
      delApplicantRequest() {
        let {
          data
        } = this.myDialog;
        this.myRequest({
          url: '/api/insurance/Insureddelete',
          data: {
            id: data.id,
          }
        }).then((res) => {
          // 获取投保人数据
          this.getApplicantDataFun();
          console.log('删除-投保人 res == ', res);
        })
      },
      /**
       * 添加投保人-弹窗-回调
       */
      addApplicantDialogChangeFun(e) {
        console.log('回调 e == ', e)
        this.applicantData.addParam.visible = false; // 关闭弹窗
        this.applicantData.addParam.options = {}; // 编辑数据
        let {
          status,
          data
        } = e;
        if (status == 1) {
          // this.applicantData.tableData.list = this.applicantData.tableData.list = resData.list.concat(data);
          // 获取投保人数据
          this.getApplicantDataFun();
        }
      },
      /**
       * 投保人选择变化
       */
      applicantDataCheckboxGroupChangeFun(e) {
        console.log('投保人选择变化 e== ', e)
        this.applicantData.tableData.checkList = [e[e.length - 1]];
        console.log('投保人选择变化 this.applicantData.tableData.checkList == ', this.applicantData.tableData.checkList);
      },
      /**
       * 被保人选择变化
       */
      recognizeeDataSelectionChangeFun(e) {
        console.log('被保人选择变化 e== ', e)
        this.recognizeeData.tableData.checkList = e;
        console.log('被保人选择变化 this.recognizeeData.tableData.checkList == ', this.recognizeeData.tableData.checkList);
        // 获取价格数据
        this.getPriceDataFun();
      },
      /**
       * 打开-添加人员-弹窗
       */
      openaddPersonnelDialogFun() {
        this.recognizeeData.addParam.visible = true; // 投保人数据
      },
      /**
       * 添加人员-弹窗-回调
       */
      addPersonnelDialogChangeFun(e) {
        console.log('回调 e == ', e)
        this.recognizeeData.addParam.visible = false; // 关闭弹窗
        this.recognizeeData.addParam.options = {}; // 编辑数据
        let {
          status,
          type,
          data = []
        } = e;
        if (status == 1) {
          let atData = this.recognizeeData.tableData.list;
          if (type == 1) { // 添加
            // 添加人员
            this.personnelAddFun(atData, data);
          } else if (type == 2) { // 编辑
            let atIndex = this.recognizeeData.addParam.index;
            console.log('添加人员-弹窗-回调 atIndex == ', atIndex)
            let amend_data = data[0];
            if (amend_data.error || amend_data.error_code) {
              delete amend_data['error'];
              delete amend_data['error_code'];
            }
            console.log('添加人员-弹窗-回调 amend_data == ', amend_data)
            atData.splice(atIndex, 1, amend_data);
            this.recognizeeData.addParam.index = ''; // 编辑数据下标
            // 设置被保人缓存
            this.setPersnnelCachedFun(atData);
          }

        }
        console.log('this.recognizeeData == ', this.recognizeeData)
      },
      /**
       * 添加人员
       */
      personnelAddFun(atData, data) {
        atData = atData || this.recognizeeData.tableData.list;
        atData = atData.concat(data);
        this.recognizeeData.tableData.list = atData;
        console.log('回调 atData == ', atData)
        console.log('this.recognizeeData == ', this.recognizeeData)
        // 设置被保人缓存
        this.setPersnnelCachedFun(atData);
      },
      /**
       * 初始化被保人数据
       */
      initPersonnelDataFun() {
        return new Promise(resolve => {
          // let refs_name = this.recognizeeData.addParam.ref;
          // let data = this.$refs[refs_name].getAtPageSessionFun() || [];
          // if(data.length > 0) {
          //   this.recognizeeData.tableData.list = data;
          // }
          // 获取缓存
          let data = this.getCachedFun(this.recognizeeData.cache.name) || [];
          console.log('初始化被保人数据 data == ', data)

          // 被保人数据查重
          let check_data = this.personnelDuplicateCheckingFun(data);


          // 被保人数据-过滤
          this.personnelDataFilterFun(check_data).then((res) => {

            let {
              result
            } = res;

            this.recognizeeData.tableData.list = result;
            // this.recognizeeData.tableData.list = data;
            console.log('this.recognizeeData == ', this.recognizeeData)
            resolve(result);

          });
        })
      },
      /**
       * 被保人数据-过滤
       */
      personnelDataFilterFun(data) {
        const _this = this;
        let default_error = {
          status: 'error',
          message: '该数据的证件类型在该保险不可用！',
        };
        let {
          cert_type
        } = this.insuranceData;
        return new Promise((resolve, reject) => {
          console.log('被保人数据-过滤1 data == ', data);
          console.log('被保人数据-过滤1 cert_type == ', cert_type);

          let result = data.map((item) => {
            let error = deepClone(default_error); // 深拷贝
            let safety_type = true; // 安全状态
            for (let i = 0; i < cert_type.length; i++) {
              let citem = cert_type[i];
              if (item.type == citem.id) {
                safety_type = false; // 异常状态
                break;
              }
            }

            // 异常状态记录异常
            if (safety_type) {
              item['error'] = error;
              _this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
            }
            return item;
          })

          console.log('被保人数据-过滤1 result == ', result);

          let safety = result.filter((item) => !item.disabled)


          resolve({
            safety,
            result,
          });
        })
      },
      /**
       * 被保人数据查重
       */
      personnelDuplicateCheckingFun(arr = []) {
        let default_error = {
          status: 'error',
          message: '异常',
        };
        if (arr.length == 0) return arr;

        // 初始化
        let init_arr = arr.map(item => {
          console.log('初始化 init_arr item == ', item)
          if (item['error']) {
            delete item['error'];
          }
          if (item['error_array']) {
            delete item['error_array'];
          }
          return item;
        })
        console.log('初始化 init_arr == ', init_arr)

        let arrLength = init_arr.length;
        for (let i = 0; i < arrLength; i++) {
          let error = deepClone(default_error); // 深拷贝
          // console.log('init_arr[i] == ',init_arr[i])
          let a_item = init_arr[i];
          let a_name = a_item.name,
            a_code = a_item.code;
          // 2
          let j = (i + 1);
          for (; j < arrLength; j++) {
            // if(!arr[j]) {
            //   console.log('下一次循环')
            //   continue;
            // }

            let b_item = init_arr[j];
            let b_name = b_item.name,
              b_code = b_item.code;

            // console.log(' ------------ ')
            // console.log('i == ',i)
            // console.log('j == ',j)
            // console.log('a_name == ',a_name)
            // console.log('b_name == ',b_name)
            // console.log('a_code == ',a_code)
            // console.log('b_code == ',b_code)
            // console.log('a_name == b_name == ',a_name == b_name)
            // console.log('a_code == b_code == ',a_code == b_code)
            // console.log('(a_name == b_name && a_code == b_code) == ',(a_name == b_name && a_code == b_code))
            // console.log(' ------------ ')
            // if()
            // if(a_name == b_name && a_code == b_code) {
            //   error.message = '名字与身份证重复';
            //   a_item['error'] = error;
            //   b_item['error'] = error;
            //   this.flagErrorMessageFun(a_item,false,error.message,true);// 标记错误信息
            //   this.flagErrorMessageFun(b_item,false,error.message,true);// 标记错误信息
            // } else
            //  if(a_name == b_name) {
            //   error.message = '名字重复';
            //   a_item['error'] = error;
            //   b_item['error'] = error;
            //   this.flagErrorMessageFun(a_item,false,error.message,true);// 标记错误信息
            //   this.flagErrorMessageFun(b_item,false,error.message,true);// 标记错误信息
            // } else
            if (a_code == b_code) {
              error.message = '证件号重复';
              a_item['error'] = error;
              b_item['error'] = error;
              this.flagErrorMessageFun(a_item, false, error.message, true); // 标记错误信息
              this.flagErrorMessageFun(b_item, false, error.message, true); // 标记错误信息
            }
          }

          // 校验是否为空
          this.verifyIsEmptyFun(a_item, default_error);
          // 校验是否为空-表格模式
          // this.verifyIsEmptyTableModeFun(a_item, default_error);
        }

        console.log('被保人数据查重 init_arr == ', init_arr)
        return init_arr;
      },
      /**
       * 校验是否为空
       */
      verifyIsEmptyFun(item, errorObj) {
        let error = deepClone(errorObj); // 深拷贝
        let {
          name,
          code,
          type,
          sex,
          birth,
          error_code,
          error_msg
        } = item;

        if (code) {
          if (type == 1) { // 身份证
            if (!this.myChecktool.identityCodeValid2(code)) {
              error.message = '身份证号码错误';
              item['error'] = error;
              this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
              // return;
            }
          } else {

            if (type == 2) { // 护照
              if (!this.REGEXP.is_passport.test(code)) {
                error.message = '护照格式错误';
                item['error'] = error;
                this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
                // return;
              }
            } else {

              let {
                cert_type
              } = this.insuranceData;
              console.log('cert_type == ', cert_type)
              let index = cert_type.findIndex(item => item.id == type);
              console.log('type == ', type)
              console.log('index == ', index)
              let item_data = cert_type[index];
              console.log('item_data == ', item_data)
              if (item_data) {
                if (item_data.check == 1 && item_data.regular) {
                  let regexp = new RegExp(item_data.regular);
                  console.log('regexp == ', regexp)
                  let regular = !(regexp.test(code));

                  if (regular) { // 校验
                    error.message = `${item_data.name}证件号码格式错误`;
                    item['error'] = error;
                    this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
                  }
                }
              }
            }
          }
        }

        // if(error_code) {
        //   error.message = error_msg;
        //   item['error'] = error;
        //   this.flagErrorMessageFun(item,false,error.message);// 标记错误信息
        // }
        if (!code) {
          error.message = '证件号异常';
          item['error'] = error;
          this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
        }
        if (!name) {
          error.message = '名称异常';
          item['error'] = error;
          this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
        } else if (!this.myChecktool.checkTextNameNotRestChars(name)) {
          error.message = '名称不能存在数字或特殊字符';
          item['error'] = error;
          this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
        }

        if (!type) {
          error.message = '证件类型异常';
          item['error'] = error;
          this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
        }
        if (!sex) {
          error.message = '性别异常';
          item['error'] = error;
          this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
        }
        if (!birth || !(/^\d{4}-\d{2}-\d{2}$/).test(birth)) {
          error.message = '生日异常';
          item['error'] = error;
          this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
        }
        // else {
        // if (code) {
        //   if(type == 1) { // 身份证
        //     if(!this.myChecktool.identityCodeValid2(code)) {
        //       error.message = '身份证号码错误';
        //       item['error'] = error;
        //       return;
        //     }
        //   } else if(type == 2){ // 护照
        //     if(!this.REGEXP.is_passport.test(code)) {
        //       error.message = '护照格式错误';
        //       item['error'] = error;
        //       return;
        //     }
        //   }
        // }
        // delete item['error'];
        // delete item['error_code'];
        // }
      },
      /**
       * 校验是否为空-表格模式
       */
      verifyIsEmptyTableModeFun(item, errorObj) {
        let error = deepClone(errorObj); // 深拷贝
        let {
          name,
          code,
          type,
          sex,
          birth,
          error_code,
          error_msg
        } = item;

        if (error_code) {
          error.message = error_msg;
          item['error'] = error;
          this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
        } else {
          if (name && name.length < 2) {
            error.message = '名称异常，名称长度至少两位';
            item['error'] = error;
            this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
          }
          if (!code) {
            error.message = '证件号异常';
            item['error'] = error;
            this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
          } else if (!name) {
            error.message = '名称异常';
            item['error'] = error;
            this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
          } else if (!type) {
            error.message = '证件类型异常';
            item['error'] = error;
            this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
          } else if (!sex) {
            error.message = '性别异常';
            item['error'] = error;
            this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
          } else if (!birth) {
            error.message = '生日异常';
            item['error'] = error;
            this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
          }

          if (code && type) {
            if (type == 1) { // 身份证
              if (!this.myChecktool.identityCodeValid2(code)) {
                error.message = '身份证【证件号码】错误';
                item['error'] = error;
                this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
                return;
              }
            } else {
              let {
                cert_type
              } = this.insuranceData;

              let index = cert_type.findIndex(item => item.id == type);
              let item_data = cert_type[index];
              console.log('item_data == ', item_data)
              if (item_data && item_data.check == 1 && item_data.regular) {
                let regexp = new RegExp(item_data.regular);
                console.log('regexp == ', regexp)
                let regular = regexp.test(code);
                console.log('regular == ', regular)
                if (!regular) { // 校验
                  error.message = `${item_data.name}【证件号码】错误`;
                  item['error'] = error;
                  this.flagErrorMessageFun(item, false, error.message); // 标记错误信息
                }
              }
            }

          }
        }
      },
      /**
       * 标记错误信息
       */
      flagErrorMessageFun(item, name, error, cover) {
        // console.log('标记错误信息 item == ',item)
        // console.log('标记错误信息 name == ',name)
        // console.log('标记错误信息 error == ',error)
        name = name || 'error_array';
        if (!Array.isArray(item[name])) {
          item[name] = [];
        }
        if (cover && (item[name] && item[name].length > 0)) {
          return;
        } else {
          item[name].push(error);
        }
      },
      /**
       * 设置被保人缓存
       */
      setPersnnelCachedFun(data) {
        // 设置缓存
        this.setCachedFun(this.recognizeeData.cache.name, data);
        // 初始化被保人数据
        this.initPersonnelDataFun();
      },
      /**
       * 编辑-被保人
       */
      revisePersonnelFun(item, index) {
        console.log('编辑-被保人 item == ', item)
        let copy_data = deepClone(item); // 深拷贝
        console.log('编辑-被保人 copy_data == ', copy_data)
        this.recognizeeData.addParam.options = copy_data; // 编辑数据
        this.recognizeeData.addParam.index = index; // 编辑数据下标
        // 打开-添加人员-弹窗
        this.openaddPersonnelDialogFun();
      },
      /**
       * 删除-被保人
       */
      delPersonnelFun(item, index) {
        console.log('删除-投保人 item == ', item)
        let message = `确认要删除第[${index + 1}]条，名为[${item.name}]的数据？`;
        if (!item.name) {
          message = `确认要删除第[${index + 1}]条数据？`;
        }
        this.myDialog = {
          data: index,
          visible: true,
          options: {
            title: '删除',
            message: message,
            is_tips: false,
            sign: 'personnel',
          },
        }
      },
      /**
       * 删除-被保人-请求
       */
      delPersonnelRequest() {
        let {
          data
        } = this.myDialog;
        let atData = this.recognizeeData.tableData.list;
        atData.splice(data, 1);
        console.log('删除-被保人 this.recognizeeData == ', this.recognizeeData)
        // 关闭当前页面的提示信息
        this.closePageMessageFun();
        this.page_message_obj = this.myMessage({
          type: 'success',
          message: '删除成功！'
        })
        // 设置被保人缓存
        this.setPersnnelCachedFun(atData);
      },
      /**
       * 初始化对话框
       */
      initMyDialogFun() {
        this.myDialog = {
          data: {},
          visible: false,
          options: {},
        }
      },
      /**
       * 对话框回调
       */
      myDialogChangeFun(e) {
        console.log('对话框回调 e == ', e);
        let {
          visible,
          status,
          message,
          data,
          name,
          sign
        } = e;
        if (status == 1) { // 确认
          // 匹配与作用
          this.matchingAndEffectFun(sign);

        } else { // 取消
          // 初始化对话框
          this.initMyDialogFun();
        }
      },
      /**
       * 匹配与作用
       */
      matchingAndEffectFun(sign) {
        switch (sign) {
          case 'applicant':
            // 删除-投保人-请求
            this.delApplicantRequest();
            break;
          case 'personnel':
            // 删除-被保人
            this.delPersonnelRequest();
            break;
          case 'personnel-gather':
            // 删除-被保人数据-多条-请求
            this.delPastePersonnelGatherRequest();
            break;
        }

        // 初始化对话框
        this.initMyDialogFun();
      },
      /**
       * 打开被保人-粘贴名单弹窗
       */
      openPastePersonnelDialogFun() {
        this.recognizeeData.pasteParam.visible = true; // 打开弹窗
        console.log('this.recognizeeData == ', this.recognizeeData)
      },
      /**
       * 被保人-粘贴名单回调
       */
      pastePersonnelDialogChangeFun(e) {
        console.log('被保人-粘贴名单回调 e == ', e)
        this.recognizeeData.pasteParam.visible = false; // 关闭弹窗
        let {
          status,
          data
        } = e;
        if (status == 1) {
          let {
            data: stickData,
            message,
            error
          } = data;
          let has_error = Boolean(error > 0);
          let time = has_error ? 12 : 5;
          // 关闭当前页面的提示信息
          this.closePageMessageFun();
          this.page_message_obj = this.myMessage({
            // customClass:  has_error ? 'paste-message-class' : '',
            duration: 1000 * time, // 5秒后消失 0/不消失
            type: has_error ? 'error' : 'success',
            message: message,
          })
          // 快捷-添加被保人函数
          console.log('被保人-粘贴名单回调 stickData == ', stickData)
          this.fastAddrecognizeeDataFun(stickData).then(res => {
            console.log('粘贴名单回调 快捷-添加被保人 res == ', res)
          });
        }
        console.log('this.recognizeeData == ', this.recognizeeData)
      },
      /**
       * 将文件提交到服务器
       * $refs：vue 语句，配合 ref="upload" 属性获取节点对象；
       * @parma file {Object}
       */
      mySubmitUploadFileFun(file) {
        console.log('将文件提交到服务器 == ', file)
        const _this = this;
        return new Promise((resolve, reject) => {

          if (file && file.status == 'ready') {
            // let { type } = file.raw;

            // file转base64
            fileToBase64(file.raw, function (base64) {
              console.log('bb');
              // base64转file
              let base64_file = dataURLToBlob(base64);
              // 获取oss签名
              _this.myGetOssSignature(file).then((res) => {
                console.log('oss 签名回调 == ', res);
                let {
                  path,
                  key,
                  policy,
                  OSSAccessKeyId,
                  accessid,
                  signature
                } = res;
                let param = {
                  key,
                  policy,
                  OSSAccessKeyId,
                  signature,
                };
                _this.upload_option.param = param;
                console.log('_this.upload_option == ', _this.upload_option)
                _this.myUploadFileToServers(param, base64_file).then((res) => {
                  resolve(path);
                });
              })
            });


          } else {
            throw (new Error('Error: There are no committable objects!'))
            reject();
          }
        })
      },
      /* ----------- 上传 excel 名单 ----------- */
      /**
       * 文件状态改变时-的钩子函数
       */
      handleChangeFun(file, fileList, name) {
        console.log('文件状态改变时 file == ', file);
        console.log('文件状态改变时 fileList == ', fileList);
        console.log('文件状态改变时 name == ', name);
        let flag = this.handleBeforeUploadFun(file);
        console.log('文件状态改变时 flag == ', flag);
        if (!flag) return;
        // 将文件提交到服务器
        this.mySubmitUploadFileFun(file).then((res) => {
          console.log('远程路径 == ', res)
          // 获取被保人名单
          this.getRecognizeetFun(res);
        }).catch(() => {});
      },
      /**
       * 上传文件之前-的钩子函数
       */
      handleBeforeUploadFun(file) {
        console.log('上传文件之前 file == ', file);

        // const isExcel = file.raw.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        // const isLt2M = file.size / 1024 / 1024 < 2;

        let {
          name
        } = file;
        const suffix = this.verifyFileTypeFun(name);
        const isExcel = (suffix == 'xlsx' || suffix == 'xls');

        if (!isExcel) {
          this.$message.error('上传方案只能是 Excel 格式!');
        }
        // if (!isLt2M) {
        //   this.$message.error('上传方案大小不能超过 2MB!');
        // }
        // return isExcel && isLt2M;
        return isExcel;
      },
      /**
       * 校验文件类型
       */
      verifyFileTypeFun(str) {
        console.log('校验文件类型 str ==', str)
        let suffix = str.slice(str.indexOf('.') + 1);
        suffix = suffix.toUpperCase(); // 转换大写
        suffix = suffix.toLowerCase(); // 转换小写
        console.log('校验文件类型 suffix ==', suffix)
        if (suffix.indexOf('.') == -1) {
          return suffix;
        }
        return this.verifyFileTypeFun(suffix);
      },
      /**
       * 获取被保人名单
       */
      getRecognizeetFun(url) {
        this.myRequest({
            url: '/api/insurance/personnelimport',
            data: {
              file_url: url,
              insurance_id: this.page_id,
            }
          })
          .then((res) => {
            console.log('获取被保人名单 res == ', res)
            let resData = res.data;
            let message = `识别${resData.data.list.length}条数据，${resData.data.success}条正常，${resData.data.fail}条异常！`;

            // 添加人员
            this.personnelAddFun(false, resData.data.list);
            // this.myMessage({
            //   type: 'success',
            //   message: resData.msg
            // })
            // 关闭当前页面的提示信息
            this.closePageMessageFun();
            let has_error = Boolean(resData.data.fail > 0);
            let time = has_error ? 12 : 5;
            this.page_message_obj = this.myMessage({
              // customClass: has_error ? 'paste-message-class' : '',
              duration: 1000 * time, // 5秒后消失 0/不消失
              type: has_error ? 'error' : 'success',
              message: message,
            })
          })
      },
      /**
       * 删除-被保人数据-多条
       */
      delPastePersonnelGatherFun() {
        let {
          checkList = []
        } = this.recognizeeData.tableData;
        let check_length = checkList.length;
        if (check_length == 0) {
          // 关闭当前页面的提示信息
          this.closePageMessageFun();
          this.page_message_obj = this.myMessage({
            type: 'error',
            message: '请勾选要删除的被保人！'
          })
          return;
        }
        console.log('删除-被保人数据-多条 checkList == ', checkList)
        this.myDialog = {
          data: {},
          visible: true,
          options: {
            title: '删除',
            message: `确认要删除勾选的${check_length}条数据？`,
            is_tips: false,
            sign: 'personnel-gather',
          },
        }
      },
      /**
       * 删除-被保人数据-多条-请求
       */
      delPastePersonnelGatherRequest() {
        let {
          checkList,
          list
        } = this.recognizeeData.tableData;
        let check_length = checkList.length;
        let count = 0;
        console.log('删除-被保人数据-多条 checkList == ', checkList);
        console.log('删除-被保人数据-多条 list == ', list);
        for (let i = 0; i < check_length; i++) {
          let i_item = checkList[i];
          let i_name = i_item.name;
          let i_code = i_item.code;
          let i_type = i_item.type;
          let i_sex = i_item.sex;
          let i_birth = i_item.birth;
          for (let j = 0; j < list.length; j++) {
            let j_item = list[j];
            let j_name = j_item.name;
            let j_code = j_item.code;
            let j_type = j_item.type;
            let j_sex = j_item.sex;
            let j_birth = j_item.birth;
            console.log('删除-被保人数据-多条 i_item == ', i_item);
            console.log('删除-被保人数据-多条 j_item == ', j_item);
            console.log('删除-被保人数据-多条 i_code == ', i_code);
            console.log('删除-被保人数据-多条 j_code == ', j_code);
            console.log('删除-被保人数据-多条 i_code == j_code == ', i_code == j_code);
            if (i_name == j_name && i_code == j_code && i_type == j_type && i_sex == j_sex && i_birth == j_birth) {
              if (count >= check_length) {
                break;
              }
              list.splice(j, 1);
              j--;
              count++;
            }
          }
        }
        this.recognizeeData.tableData.list = list;
        this.recognizeeData.tableData.checkList = [];
        // 设置被保人缓存
        this.setPersnnelCachedFun(list);
        // 关闭当前页面的提示信息
        this.closePageMessageFun();
        this.page_message_obj = this.myMessage({
          type: 'success',
          message: '删除成功！'
        })
        console.log('删除-被保人数据-多条 list == ', list);
        console.log('删除-被保人数据-多条 checkList == ', checkList);
        console.log('删除-被保人数据-多条 this.recognizeeData. == ', this.recognizeeData);
      },
      /**
       * 表格-核实-被保人名单
       */
      tableVerifyPersonnelFun({
        row,
        rowIndex
      }) {
        if (row.error) {
          let {
            status
          } = row.error;
          if (status == 'error') {
            return 'error-row';
          }
        }
        return '';
      },
      /* ----------- 上传 excel 名单 end ----------- */
      /**
       * 导出错误名单
       */
      exportErrorListFun() {
        let {
          list
        } = this.recognizeeData.tableData;
        let data = list.filter(item => item.error);
        console.log('导出错误名单 data == ', data)
        if (data.length == 0) {
          // 关闭当前页面的提示信息
          this.closePageMessageFun();
          this.page_message_obj = this.myMessage({
            message: '暂无错误名单！'
          })
          return;
        }

        // 结果
        let result = data.map((item) => {
          let {
            type,
            sex,
            error
          } = item;
          item.error_msg = error.message;

          // 证件类型
          if (type == 1) {
            item.type = '身份证';
          } else if (type == 2) {
            item.type = '护照';
          }

          // 性别
          if (sex == 1) {
            item.sex = '男';
          } else if (sex == 2) {
            item.sex = '女';
          } else {
            item.sex = '未知';
          }

          return item;
        })
        // 导出错误名单-请求
        this.exportErrorListRequest(result).then(res => {
          console.log('导出错误名单-请求 res == ', res)
          let resData = res.data.data;
          console.log('导出错误名单-请求 resData.url == ', resData.url)
          // window.open(resData.url,'_blank');
          // 下载并保存文件
          this.myDownloadFile(resData.url);
        });
      },
      /**
       * 导出错误名单-请求
       */
      exportErrorListRequest(data) {
        console.log('导出错误名单-请求 data == ', data)
        return this.myRequest({
          url: '/api/insurance/personnelerrorexport',
          data: {
            applicant: data,
          }
        })
        // .then((res) => {
        //   console.log('导出错误名单-请求 res == ',res)
        // })
      },
      /**
       * 下载模板
       */
      downloadModelFun() {
        let url =
          'https://bdt-file.oss-cn-beijing.aliyuncs.com/bands-team/images/insurance-img/%E4%BD%B0%E5%9B%A2%E4%B9%90-%E4%BF%9D%E9%99%A9%E5%90%8D%E5%8D%95%E5%BD%95%E5%85%A5%E6%A8%A1%E7%89%88.xlsx';
        let urlArr = url.split('/')
        let name = decodeURIComponent(urlArr[urlArr.length - 1]);
        if (!name) name = '佰团乐-保险名单录入模版';
        // window.open(url,_blank');
        // 下载并保存文件
        this.myDownloadFile(url + '?v=' + Date.now(), name);
      },
      /**
       * 获取当前选中计划与计划ids
       */
      getPlanIdFun(data, callback) {
        let {
          current,
          current_days,
          plan,
          confirmed_date_start_data,
          confirmed_date_end_data
        } = data;
        let tPlan = plan[current];
        let tPrice = plan[current].time_limit[current_days].money;
        let tDate = {
          start: confirmed_date_start_data,
          end: confirmed_date_end_data
        };

        let result = {
          t_id: tPlan.id,
          t_plan: tPlan,
          t_date: tDate,
          t_price: tPrice,
        }
        if (callback) {
          callback(result);
        }
        return result;
      },
      /**
       * 获取投保人信息
       */
      getApplicantInfo(data, callback) {

        let {
          checkList,
          list
        } = data.tableData;
        if (!(checkList.length > 0)) return {};

        let current = checkList[0];
        // let item = list[current];
        let result = list[current];

        // let result = {
        //   id: item.id,
        //   current: current,
        //   item: item,
        //   list: list,
        // }
        if (callback) {
          callback(result);
        }
        return result;
      },
      /**
       * 获取参保人信息
       */
      getRecognizeeInfo(data, callback) {

        let {
          checkList,
          list
        } = data.tableData;
        // let result = checkList;
        let result = checkList.map(item => {
          let {
            birth,
            code,
            name,
            sex,
            tel,
            type
          } = item;
          return {
            birth,
            code,
            name,
            sex,
            tel,
            type
          };
        });

        if (callback) {
          callback(result);
        }
        return result;
      },
      /**
       * 保单申请-点击按钮
       */
      policyApplicationClickFun(status = 1) {
        // 校验数据
        let flag = this.verifyDataFun();
        if (!flag) return;

        const _this = this;

        // 保单申请-获取参数
        this.policyApplicationParamFun((res) => {
          let {
            formData
          } = res;
          // 保单申请-请求
          this.policyApplicationRequest(formData).then(res => {
            console.log('保单申请-请求 res == ', res);
            let order_id = this.again_order.param.id;
            if (!order_id) {
              order_id = res.data.data.order_id;
              this.again_order.param.id = order_id;
            }
            // status == 2 是为保存订单
            if (status == 2) {
              // 保险订单保存
              this.insuranceSaveOrderFun(order_id);
              return;
            }

            // 选择支付-开关
            this.choosePayTaggleFun(1);
          });
        })
      },
      /**
       * 保险订单保存
       */
      insuranceSaveOrderFun(order_id) {
        let type = 'error';
        let message = '失败';
        if (order_id) {
          type = 'success';
          message = '成功';
          this.again_order.param.id = order_id;
        }
        // 关闭当前页面的提示信息
        this.closePageMessageFun();
        this.page_message_obj = this.myMessage({
          type: type,
          message: `订单保存${message}！`
        })
      },
      /**
       * 保单申请-获取参数
       */
      policyApplicationParamFun(callback) {
        let {
          page_id,
          again_order,
          insuranceData,
          applicantData,
          recognizeeData
        } = this;
        console.log('保险id page_id == ', page_id)
        console.log('计划 insuranceData == ', insuranceData)
        console.log('投保人 applicantData == ', applicantData)
        console.log('被保人 recognizeeData == ', recognizeeData)

        // 获取当前选中计划与计划id
        let plan = this.getPlanIdFun(insuranceData);
        console.log('plan == ', plan)

        // 获取投保人信息
        let applicant_info = this.getApplicantInfo(applicantData);
        console.log('applicant_info == ', applicant_info)

        // 获取参保人信息
        let recognizee_info = this.getRecognizeeInfo(recognizeeData);
        console.log('recognizee_info == ', recognizee_info)


        let formData = {
          id: page_id, //	是	string	保险id
          plan_id: plan.t_id, //	是	string	计划 - id
          guarantee_start_time: plan.t_date.start, //	是	string	保障开始日期（格式：yyyy-MM-dd HH:mm:ss）
          guarantee_end_time: plan.t_date.end, //	是	string	保障截止日期（格式：yyyy-MM-dd HH:mm:ss）
          dest_zone: '', //	否	string	目的地（个别产品必填）
          is_invoice: '0', //	否	object	是滞发票【0否，1是】
          insured_id: applicant_info.id, //	是	string	投保人id
          // applicant: [{ // 是	array	被保人数据
          //   name: '', //	是	string	被保人 - 姓名
          //   type: '', //	是	string	被保人 - 证件类型【1身份证】
          //   code: '', //	是	string	被保人 - 证件号
          //   tel: '', //	是	string	被保人 - 电话
          // }],
          applicant: recognizee_info,
          order_id: again_order.param.id, // 否	string	保单 - id（修改保单为必填项）（注：再来一单时不要填写该参数）
        };

        console.log('保单申请参数 formData == ', formData)

        // 结果
        let result = {
          plan,
          applicant_info,
          recognizee_info,
          formData
        }
        console.log('保单数据 result == ', result)

        if (callback) {
          callback(result);
        }
        return result;
      },
      /**
       * 保单申请-请求
       */
      policyApplicationRequest(opt) {
        return this.myRequest({
          url: '/api/insurance/buy',
          data: opt
        })
        // .then((res) => {
        //   console.log('保单申请-请求 res == ',res)
        // })
      },
      /**
       * 校验数据
       */
      verifyDataFun() {
        let {
          insuranceData,
          recognizeeData
        } = this;
        let select_data = recognizeeData.tableData.checkList;
        let select_data_length = select_data.length;

        if (select_data_length == 0) {
          // 关闭当前页面的提示信息
          this.closePageMessageFun();
          this.page_message_obj = this.myMessage({
            message: '请选择被保人!',
          });
          return false;
        }

        if (select_data_length > insuranceData.buy_max_number) {
          // 关闭当前页面的提示信息
          this.closePageMessageFun();
          this.page_message_obj = this.myMessage({
            message: `单次购买被保人最多${insuranceData.buy_max_number}人，如超过${insuranceData.buy_max_number}人请分批购买`
          });
          return false;
        }

        // for(let i = 0; i < select_data_length; i++ ) {
        //   let item = select_data[i];
        //   if(item.error) {
        //     if(item.error.status = 'error') {
        //       this.myMessage({
        //         message: `第 ${i + 1} 条数据错误，错误信息： ${item.error.message}；请处理！`
        //       });
        //       return false;
        //     }
        //   }
        // }

        // 获取错误信息
        let err_data = select_data.filter((item, index) => {
          if (item.error) {
            if (item.error.status = 'error') {
              return item;
            }
          }
        })
        if (err_data.length > 0) {
          let name_list = [];
          err_data.map((item, index) => {
            name_list.push(item.name);
          })
          let name_str = name_list.join(',');
          if (name_list.length > 3) {
            name_list.splice(3);
            name_str = name_list.join(',') + '...';
          }
          // 关闭当前页面的提示信息
          this.closePageMessageFun();
          this.page_message_obj = this.myMessage({
            message: `名为【${name_str}】等人的数据错误，请处理！`
          });
          return false;
        }

        return true;
      },
      /**
       * 获取价格数据
       */
      getPriceDataFun() {
        console.log('NP == ', NP)
        // 保单申请-获取参数
        this.policyApplicationParamFun((res) => {
          let {
            plan,
            applicant_info,
            recognizee_info
          } = res;
          console.log('plan == ', plan)
          console.log('applicant_info == ', applicant_info)
          console.log('recognizee_info == ', recognizee_info)

          let unit_price = plan.t_price; // 单价
          let people_number = recognizee_info.length == 0 ? 1 : recognizee_info.length; // 人数
          let total_price = NP.times(Number(unit_price), Number(people_number)); // 总价 = 单价 * 人数
          let mean_price = NP.divide(Number(total_price), Number(people_number)); // 平均价 = 总价 / 人数

          // 总价小数点后两位
          let total_price_toFixed = total_price;
          if (total_price) {
            // let total_price_floor = Math.floor(total_price * 100) / 100;
            // total_price_toFixed = total_price_floor.toFixed(2);
            // console.log('获取价格数据 total_price_floor == ', total_price_floor)
            console.log('获取价格数据 total_price == ', total_price)
            total_price_toFixed = total_price.toFixed(2);
          }

          // 平均价小数点后两位
          let mean_price_toFixed = mean_price;
          if (mean_price) {
            // let mean_price_floor = Math.floor(mean_price * 100) / 100;
            // console.log('获取价格数据 mean_price_floor == ', mean_price_floor)
            // mean_price_toFixed = mean_price_floor.toFixed(2);
            mean_price_toFixed = mean_price.toFixed(2);
          }

          // 订单数据
          this.orderData = {
            people_number: people_number,
            price: {
              total_price: total_price_toFixed, // 总价
              mean_price: mean_price_toFixed, // 平均价
            },
          }

          console.log('获取价格数据 total_price_toFixed == ', total_price_toFixed)
          console.log('获取价格数据 mean_price_toFixed == ', mean_price_toFixed)


        });
      },
      /**
       * 打开支付弹窗
       */
      openPayWindowFun(opt) {
        this.pay_config.payee = opt.payee || '';
        this.pay_config.price = opt.price || '';
        this.pay_config.qr_code = opt.qr_code;
        this.pay_config.visible = true;
      },
      /**
       * 支付结果回调
       */
      payResultChangeFun(e) {
        console.log('支付结果回调 e == ', e)
        let {
          visible,
          status,
          message,
          data
        } = e;
        this.pay_config.visible = visible;
        // 自定义清除定时器
        this._clearIntervalFun();
        // 是否支付成功
        if (status == 1) {
          // 发起录入后
          this.sendEnteringAfterFun(data);
        } else {
          // 关闭当前页面的提示信息
          this.closePageMessageFun();
          this.page_message_obj = this.myMessage({
            message: message,
          });
        }
      },
      /**
       * 选择支付-开关
       */
      choosePayTaggleFun(flag) {
        let result;
        switch (+flag) {
          case 1:
            result = true;
            break;
          case 2:
            result = false;
            break;
          default:
            result = !this.pay_config.select.visible;
            break;
        }
        this.pay_config.select.visible = result;
      },
      /**
       * 选择支付-结果回调
       */
      choosePayResultChangeFun(e) {
        console.log('选择支付结果回调 e == ', e)
        const _this = this;
        let {
          visible,
          status,
          message,
          data
        } = e;
        this.pay_config.select.visible = visible;
        // 自定义清除定时器
        this._clearIntervalFun();
        // 是否选择支付成功
        if (status == 1) {
          let {
            id,
            name,
            payment,
            is_parent_pay,
          } = data
          this.pay_config.select.metadata = data;
          this.pay_config.select.value = id;
          this.pay_config.select.pay_mode = name;
          this.pay_config.select.is_parent_pay = is_parent_pay;

          // 保单支付
          this.myInsurancePayRequest(this.again_order.param.id, id, is_parent_pay).then(res => {
            console.log('保单支付 res == ', res)
            let resData = res.data.data;
            let copyResData = deepClone(resData);
            let skpiParam = Object.assign(copyResData, {
              parent_pay_status: {
                pay_status: '1', // 1/支付成功 2/支付失败
                pay_status_msg: '支付成功', // 支付成功
              }
            }, {
              order_type: 'insurance_order'
            });

            if (res.data.code != 0) {
              this.myMessage({
                message: res.data.msg,
              });
              return;
            }

            console.log('保单支付 resData == ', resData)
            console.log('保单支付 skpiParam == ', skpiParam)

            // 如果是钱包支付
            if (payment == 'WalletPay' || payment == 'Buffering') {

              // 支付结果回调
              _this.payResultChangeFun({
                visible: false,
                status: 1, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
                message: '支付成功',
                data: skpiParam,
              });
              return
            }

            // 循环请求
            this.myLoopRequest({
              id: skpiParam.order_id,
              type: skpiParam.order_type
            }, function (res) {
              // console.log('myLoopRequest == ',res)
              let {
                timer,
                data
              } = res;

              // 获取定时器
              if (!_this.pay_config.pay_timer) {
                _this.pay_config.pay_timer = timer;
                // 打开支付弹窗
                _this.openPayWindowFun({
                  payee: skpiParam.order_payee,
                  price: _this.orderData.price.total_price,
                  qr_code: skpiParam.pay
                });
              }
              // console.log('_this.pay_config.pay_timer == ',_this.pay_config.pay_timer)

              // console.log('data.pay_status == ',data.pay_status)
              // 是否已支付
              if (data.pay_status == 1) {
                console.log('是否已支付 == ', data.pay_status)
                // 支付结果回调
                _this.payResultChangeFun({
                  visible: false,
                  status: 1, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
                  message: '支付成功',
                  data: skpiParam,
                });
              } else if (data.pay_status == 2) { // 异常
                // 支付结果回调
                _this.payResultChangeFun(skpiParam);
              } else if (status == 3) { // 超时
                // 支付结果回调
                _this.payResultChangeFun(skpiParam);
              }
            })
          });

        } else {
          // 关闭当前页面的提示信息
          this.closePageMessageFun();
          this.page_message_obj = this.myMessage({
            message: message,
          });
        }
      },
      /**
       * 自定义清除定时器
       */
      _clearIntervalFun() {
        if (this.pay_config.pay_timer) {
          clearInterval(this.pay_config.pay_timer);
          this.pay_config.pay_timer = null;
        }
      },
      /**
       * 发起录入后
       */
      sendEnteringAfterFun(data) {
        // 初始化页面 data 数据
        // this.initPageDataFun();
        // 删除-被保人-本地缓存
        this.myRemoveStorage(this.recognizeeData.cache.name);
        let result = encodeURIComponent(JSON.stringify(data));
        // 跳转路由
        this.myRouterPush({
          path: '/insurance/succeed-pay',
          query: {
            data: result,
          },
        });
      },
      /**
       * 关闭当前页面的提示信息
       */
      closePageMessageFun() {
        let {
          page_message_obj
        } = this;
        if (page_message_obj) {
          page_message_obj.close();
          page_message_obj = null;
        }
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前
      // 自定义清除定时器
      this._clearIntervalFun();
      // 关闭当前页面的提示信息
      this.closePageMessageFun();
      // 删除-被保人-本地缓存
      this.myRemoveStorage(this.recognizeeData.cache.name);
    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .details-container {
    box-sizing: border-box;
    border-bottom: 72px solid #f5f5f5;
  }

  .page-title {
    box-sizing: border-box;
    padding: 0 $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 41px auto;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    color: #444343;
  }

  .details-main {
    box-sizing: border-box;
    padding: 0 $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;

    &.applicant-info {
      .module-title {
        .title-box {
          align-items: center;
        }

        .make-invoice-look {
          margin-left: 24px;
          cursor: pointer;
          text-decoration: underline;
          font-size: 12px;
          color: #E62129;
        }

        .rest-box {
          flex: 1;
          min-width: 1;
          display: flex;
          justify-content: flex-end;

          .add-applicant {
            width: 160px;

            &::v-deep {
              .fake-btn {
                width: 100%;
                height: 36px;
                padding: 0;
                font-size: 16px;
                color: #E62129;
                border: 1px solid #E62129;
                background: #fff;
                outline: none;

                .iconfont {
                  margin-right: 10px;
                  font-size: 12px;
                }

                &.el-button:hover,
                &.el-button:focus,
                &.el-button:active {
                  color: none;
                  border-color: none;
                  background-color: none;
                }

                &.el-button:active {
                  color: $active-default-color;
                  border-color: $active-default-color;
                }
              }
            }
          }
        }
      }

      .applicant-table {
        box-sizing: border-box;
        padding: 24px 0 80px;

        .radio-box {
          &::v-deep {
            .el-radio__input.is-checked+.el-radio__label {
              color: #E62129;
            }

            .el-radio__inner:hover,
            .el-radio__input.is-checked .el-radio__inner {
              border-color: #E62129;
              background: transparent;
            }

            .el-radio__inner::after {
              width: 58%;
              height: 58%;
              background: #E62129;
            }
          }
        }

        .checkbox-box {
          &::v-deep {
            .el-checkbox__label {
              display: none;
            }

            .el-checkbox__input.is-focus .el-checkbox__inner {
              border-color: #E62129;
            }

            .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: transparent;
              border-color: #E62129;
            }

            .el-checkbox__inner::after {
              border-color: #E62129;
            }
          }
        }

        &::v-deep {
          .el-table th.el-table__cell {
            background: #F0F0F0;
          }

          .fake-table-th {
            padding: 10px 0;
            background: #F0F0F0;
            font-weight: 400;
            font-size: 16px;
            color: #444343;
          }

          .fake-table-tr {
            font-size: 16px;
            color: #787878;
            background: #fff;

            &.error-row {
              background: red;
            }
          }

          .el-table--border,
          .el-table--group {
            border: 1px solid #e6e6e6;
          }

          .el-table--border .el-table__cell,
          .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
          .el-table--border th.el-table__cell,
          .el-table__fixed-right-patch {
            border: none;
          }

          .el-table td.el-table__cell,
          .el-table th.el-table__cell.is-leaf {
            border-top: 1px solid #f0f0f0;
          }

          .el-table--border::after,
          .el-table--group::after,
          .el-table::before {
            display: none;
          }

          .el-table,
          .el-table__expanded-cell,
          .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
            background: transparent;
          }

          .el-table .el-table__cell {
            height: 66px;
          }
        }

        .control-list {
          font-size: 16px;
          color: #787878;

          .control-item {
            cursor: pointer;

            &:hover {
              color: #E62129;
            }

            &.control-changes {}

            &.control-deletes {
              margin-left: 18px;
            }
          }
        }
      }
    }

    &.recognizee-info {
      .module-title {
        .title-box {
          align-items: center;
        }

        .descriptions {
          margin-left: 24px;
          cursor: pointer;
          // text-decoration: underline;
          font-size: 12px;
          color: #E62129;
        }
      }

      .operation-module {
        margin: 33px 35px 39px;
        display: flex;
        justify-content: space-between;

        .operation-list {
          display: flex;

          .ol-item {
            margin-left: 30px;

            &::v-deep {
              .fake-btn {
                width: 100%;
                height: 36px;
                padding: 0 15px;
                font-size: 16px;
                color: #787878;
                border: 1px solid #CCCCCC;
                background: #fff;
                outline: none;

                &.el-button:hover,
                &.el-button:focus,
                &.el-button:active {
                  color: none;
                  border-color: none;
                  background-color: none;
                }

                &.el-button:active {
                  color: $active-default-color;
                  border-color: $active-default-color;
                  background-color: #fff;
                }
              }
            }

            // &:first-of-type {
            &.color-diff {
              margin-left: 0;

              &::v-deep {
                .fake-btn {
                  color: #fff;
                  border: 1px solid #E62129;
                  background: #E62129;
                }
              }
            }
          }

          .upload-module {
            display: flex;

            .upload-main {
              margin-right: 16px;

              &::v-deep {
                .el-upload--picture-card {
                  border: none;
                }
              }

              &.hide-upload {
                &::v-deep .el-upload {
                  display: none;
                }
              }

              &.identity-card {
                &::v-deep {
                  .el-upload--picture-card {
                    width: 192px;
                    height: 162px;
                    outline: none;
                  }

                  .el-upload-list--picture-card .el-upload-list__item {
                    width: 192px;
                    height: 162px;
                  }
                }

                .befoer-box {
                  width: 192px;
                  height: 162px;
                  line-height: 1.5;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;

                  .bb-cover {
                    width: 150px;
                    height: 100px;
                    margin: 0 auto;
                  }

                  .bb-text {
                    margin-top: 4px;
                    font-size: 12px;
                    color: #444343;

                    .diff-style {
                      color: #E62129;
                    }
                  }
                }
              }

              .default-befoer,
              .befoer-box {
                border-radius: 4px;
                overflow: hidden;
                border: 1px dashed transparent;
                background: #fff;

                &:hover {
                  border-color: rgb(50, 184, 247);
                }
              }

              &::v-deep {
                .el-upload-list--picture-card .el-upload-list__item-thumbnail {
                  object-fit: scale-down;
                }
              }

              .default-befoer {
                width: 100%;
                height: 100%;
                line-height: 1.5;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &::v-deep {
                  .el-upload--picture-card {
                    border: none;
                  }
                }

                .db-icon {
                  display: inline-block;
                  width: 36px;
                  height: 36px;
                  line-height: 36px;
                  color: #AAAAAA;

                  .iconfont {
                    font-size: 36px;
                  }
                }

                .db-text {
                  box-sizing: border-box;
                  padding: 16px 16px 0;
                  font-size: 12px;
                  color: #AAAAAA;
                }
              }
            }
          }
        }
      }

      .recognizee-table {
        box-sizing: border-box;
        padding: 0 0 80px;

        .radio-box {
          &::v-deep {
            .el-radio__input.is-checked+.el-radio__label {
              color: #E62129;
            }

            .el-radio__inner:hover,
            .el-radio__input.is-checked .el-radio__inner {
              border-color: #E62129;
              background: transparent;
            }

            .el-radio__inner::after {
              width: 58%;
              height: 58%;
              background: #E62129;
            }
          }
        }

        &::v-deep {
          .el-checkbox__label {
            display: none;
          }

          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: transparent;
            border-color: #E62129;
          }

          .el-checkbox__input.is-focus .el-checkbox__inner,
          .el-checkbox__inner::after {
            border-color: #E62129;
          }

          .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
            background-color: #E62129;
          }
        }

        &::v-deep {
          .el-table th.el-table__cell {
            background: #F0F0F0;
          }

          .fake-table-th {
            padding: 10px 0;
            background: #F0F0F0;
            font-weight: 400;
            font-size: 16px;
            color: #444343;
          }

          .fake-table-tr {
            font-size: 16px;
            color: #787878;
            background: #fff;

            &.error-row {
              color: red;
            }
          }

          .el-table--border,
          .el-table--group {
            border: 1px solid #e6e6e6;
          }

          .el-table--border .el-table__cell,
          .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
          .el-table--border th.el-table__cell,
          .el-table__fixed-right-patch {
            border: none;
          }

          .el-table td.el-table__cell,
          .el-table th.el-table__cell.is-leaf {
            border-top: 1px solid #f0f0f0;
          }

          .el-table--border::after,
          .el-table--group::after,
          .el-table::before {
            display: none;
          }

          .el-table,
          .el-table__expanded-cell,
          .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
            background: transparent;
          }

          .el-table .el-table__cell {
            height: 66px;
          }
        }

        .control-list {
          font-size: 16px;
          color: #787878;

          .control-item {
            cursor: pointer;

            &:hover {
              color: #E62129;
            }

            &.control-changes {}

            &.control-deletes {
              margin-left: 18px;
            }
          }
        }
      }
    }

    &.summary-info {
      box-sizing: border-box;
      padding: 30px $theme-widht-whiteedge;
      ;

      .module-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .summary-module {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .sm-list {
          display: flex;

          .sl-item {
            margin-left: 85px;
            font-size: 16px;

            &:first-of-type {
              margin-left: 0;
            }

            .sl-key {
              color: #444343;
            }

            .sl-val {
              // font-weight: 700;
              // font-size: 24px;
              font-size: 18px;
              color: #E62129;
            }
          }
        }

        .sl-control {
          display: flex;
          margin-left: 80px;

          .control-item {
            width: 110px;
            margin-left: 32px;

            &::v-deep {
              .fake-btn {
                width: 100%;
                height: 40px;
                padding: 0;
                font-size: 16px;
                color: #787878;
                border: 1px solid #CCCCCC;
                background: #fff;
                outline: none;

                &.el-button:hover,
                &.el-button:focus,
                &.el-button:active {
                  color: none;
                  border-color: none;
                  background-color: none;
                }

                &.el-button:active {
                  color: $active-default-color;
                  border-color: $active-default-color;
                  background-color: #fff;
                }
              }
            }

            &:first-of-type {
              margin-left: 0;
            }

            &.save-btn {
              &::v-deep {
                .fake-btn {
                  color: #FFFFFF;
                  border: 1px solid #AAAAAA;
                  background: #AAAAAA;
                }
              }
            }

            &.submit-btn {
              &::v-deep {
                .fake-btn {
                  color: #FFFFFF;
                  border: 1px solid #E62129;
                  background: #E62129;
                }
              }
            }
          }
        }
      }
    }

    &.server-writ {
      .sw-module {
        box-sizing: border-box;
        padding: 8px 0;

        &::v-deep {
          .el-collapse {
            border: none;

            .el-collapse-item {
              &:last-of-type {
                &.is-active {
                  .el-collapse-item__wrap {
                    border-bottom: none;
                  }
                }
              }
            }
          }

          .el-collapse-item__header {
            min-height: 60px;
            box-sizing: border-box;
            padding-left: 24px;
            position: relative;
            border-bottom: none;
            font-weight: 400;
            font-size: 16px;
            color: #444343;
          }

          .el-collapse-item__arrow {
            position: absolute;
            left: 0;
            font-weight: 700;
            font-size: 18px;
            color: #444343;
          }
        }
      }
    }
  }

  .module-title {
    box-sizing: border-box;
    padding: 0 70px 0 20px;
    border-bottom: 1px solid #CCCCCC;
    height: 76px;
    display: flex;
    align-items: center;

    .title-box {
      display: flex;

      .title-text {
        font-weight: 700;
        font-size: 18px;
        color: #444343;
      }
    }

    // .rest-box {}
  }

  .form-container {
    .form-module {
      &.selected-box {
        .picker-date {
          display: flex;
          align-items: center;

          .pd-item {
            &.timer {
              margin-left: 12px;
              margin-right: 24px;

              &::v-deep {

                .el-date-editor.el-input,
                .el-date-editor.el-input__inner {
                  width: 100px;
                }
              }
            }

            &.end-timer {
              font-size: 16px;
              color: #444343;

              .unit {
                box-sizing: border-box;
                padding: 0 17px;
                color: #787878;
              }
            }
          }
        }
      }

      &.price-box {
        box-sizing: border-box;
        padding: 20px 0;
        margin-top: 20px;
        border-top: 1px solid #F0F0F0;

        .fm-itme {
          margin-top: 0;
        }
      }

      .fm-itme {
        margin-top: 20px;
      }
    }
  }

  .tags-place {
    .tp-list {
      box-sizing: border-box;
      padding-top: 3px;
      display: flex;

      .tl-item {
        box-sizing: border-box;
        padding: 0 12px;
        margin-left: 23px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        // min-width: 76px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        color: #787878;
        cursor: pointer;

        &>span {
          cursor: pointer;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &.active {
          border-color: #E62129;
          background: #E62129;
          color: #FFFFFF;
        }
      }
    }
  }

  .swiper-palce {
    width: 100%;
    background: #f2f2f2;
    border-radius: 10px;
  }

  .price-place {
    position: relative;
    top: -6px;
    color: #E62129;

    .price-unit {
      font-weight: 400;
      font-size: 18px;
    }

    .price-text {
      font-weight: 500;
      font-size: 36px;
    }
  }

</style>
