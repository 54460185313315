<template>
  <div class="paste-container">
    <el-dialog width="1000px" top="10vh" center :show-close="false" :visible.sync="mapVisible"
      :close-on-click-modal="false" :before-close="dialogBeforeCloseFun">
      <div class="paste-body">
        <h3 class="paste-title">粘贴名单</h3>
        <div class="paste-main">
          <div class="rule">
            <div class="key">粘贴规则：</div>
            <div class="val">
              <!-- <p>一行一个名单信息，空格分隔；姓名、身份证号必填，电话选填。</p> -->
              <p>一行一个名单信息，空格分隔，必须按顺序；</p>
              <!-- <p>如：姓名 证件类型 证件号码 性别 出生年月日 电话号码</p> -->
            </div>
          </div>
          <div class="form-module">
            <div class="fm-itme width-aotu">
              <!-- <div class="fm-key">
                <div class="key-title required-style">简述受伤经过：</div>
              </div> -->
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box textarea-style">
                    <!-- <el-input type="textarea" :rows="15" resize="none" v-model="valueText"
                      :placeholder="'身份证示例：团小乐 身份证 110101********0036 男 1990-01-01 158****8888（选填）\r护照示例：团小乐 护照 PE4****24 男 1990-01-01 158****8888（选填）\r军官证示例：团小乐 军官证 军54****24 男 1990-01-01 158****8888（选填）\r港澳通行证示例：团小乐 港澳通行证 C54****24 男 1990-01-01 158****8888（选填）\r台湾通行证示例：团小乐 台湾通行证 454****24 男 1990-01-01 158****8888（选填）'"
                      @input="inputChangeFun($event,'valueText')">
                    </el-input> -->
                    <el-input type="textarea" :rows="15" resize="none" v-model="valueText"
                      :placeholder="'身份证示例：团小乐 身份证 110101********0036 男 1990-01-01 158****8888（选填）\r护照示例：团小乐 护照 PE4****24 男 1990-01-01 158****8888（选填）'"
                      @input="inputChangeFun($event,'valueText')">
                    </el-input>
                    <!-- <div class="limit-to">{{formData.content_msg.length}}/200</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="control">
            <li class="item cancel">
              <el-button class="fake-btn" @click="_handleEmpty">
                <span>清空</span>
              </el-button>
            </li>
            <li class="item cancel">
              <el-button class="fake-btn" @click="_handleCancle">
                <span>取消</span>
              </el-button>
            </li>
            <li class="item confirm">
              <el-button class="fake-btn" @click="_handleConfirm">
                <span>确认</span>
              </el-button>
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Checktool from '@/utils/check_fun.js';
  const checktool = new Checktool();
  // import {
  //   deepClone, // 深拷贝
  // } from '@/utils/utils_fun.js';
  export default {
    // 允许组件模板递归地调用自身
    name: 'paste-personnel-dialog',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      certType: {
        type: Array,
        default: () => {
          // value: [
          //   {id: "1", name: "身份证"},
          //   {id: "2", name: "护照"},
          //   {id: "3", name: "军官证"},
          //   {id: "4", name: "港澳通行证"},
          //   {id: "5", name: "台湾通行证"},
          //   {id: "6", name: "其它"},
          // ],
          return [];
        }
      },
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        mapVisible: this.visible,
        valueText: '',
        certTypeOptions: {
          options: [],
          value: '',
          index: '',
        }
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      visible: function (e) {
        this.mapVisible = e;
        console.log('checktool == ', checktool);
      },
      certType: function (e) {
        let index = 0;
        this.certTypeOptions.options = e;
        this.certTypeOptions.value = e[index];
        this.certTypeOptions.index = index;
        console.log('this.certTypeOptions == ', this.certTypeOptions);
      },
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      // 关闭弹窗
      _handleClose() {
        // 告知弹窗变化
        this.informChangeFun();
      },
      // 确定按钮
      _handleConfirm() {

        // 加载状态
        let loading = this.myLoading();

        // 获取粘贴的数据
        let result = this.getPasteDataFun();
        if (result.length == 0) {
          // 关闭加载状态
          if (loading) {
            loading.close();
          }
          return;
        }



        let data_info = {
          data: result,
          total: result.length,
          sueeccs: 0,
          error: 0,
          message: '',
        };

        result.forEach((item) => {
          let {
            name,
            type,
            code,
            birth,
            sex
          } = item;
          if (name && type && code) {
            if (type == 1) { // 身份证
              if (!this.myChecktool.identityCodeValid2(code)) {
                data_info.error++
              } else {
                data_info.sueeccs++
              }
            } else {

              // if (type == 2) { // 护照
              //   if (!this.REGEXP.is_passport.test(code) || !birth || !sex) {
              //     data_info.error++
              //   } else {
              //     data_info.sueeccs++
              //   }
              // }

              let {
                certTypeOptions
              } = this;
              let index = certTypeOptions.options.findIndex(item => item.id == type);
              let item_data = certTypeOptions.options[index];
              console.log('item_data == ', item_data)
              if (item_data && item_data.check == 1 && item_data.regular) {
                let regexp = new RegExp(item_data.regular);
                console.log('regexp == ', regexp)
                let regular = !(regexp.test(code));
                console.log('regular == ', regular)
                if (regular || !birth || !sex) { // 校验
                  data_info.error++
                } else {
                  data_info.sueeccs++
                }
              }

            }
          } else {
            data_info.error++
          }
        })

        // 关闭加载状态
        if (loading) {
          loading.close();
        }

        data_info.message = `识别${data_info.total}条数据，${data_info.sueeccs}条正常，${data_info.error}条异常！`;

        this.informChangeFun({
          visible: false,
          status: 1, // 1/确认 2/取消 3/取消，关闭弹窗
          message: '确认',
          data: data_info,
          event: 'confirm'
        });
      },
      // 清空文本
      _handleEmpty() {
        this.valueText = '';
      },
      // 取消按钮
      _handleCancle() {
        this.informChangeFun({
          visible: false,
          status: 2, // 1/确认 2/取消 3/取消，关闭弹窗
          message: '取消',
          data: '',
          event: 'cancle'
        });
      },
      noop() {},
      /**
       * 输入框监听变化
       */
      inputChangeFun(event, name) {
        console.log('输入框监听变化 event == ', event)
        // console.log('输入框监听变化 name == ',name)
        // console.log('输入框监听变化 arr == ',arr)
        // console.log('输入框监听变化 item == ',item)
      },
      /**
       * 默认变化通知
       */
      informDefaultchangeFun(opt) {
        this.$emit('on-change', opt);
      },
      /**
       * 关闭弹窗前
       */
      dialogBeforeCloseFun(done) {
        done();
        console.log('关闭弹窗前 == ', this.mapVisible)
        // 告知弹窗变化
        this.informChangeFun();
      },
      /**
       * 告知弹窗变化
       */
      informChangeFun(opt) {
        let default_data = {
          visible: false,
          status: 3, // 1/成功 2/失败 3/取消
          message: '取消',
          data: '',
          event: 'close',
        };
        let result = Object.assign(default_data, opt);
        this.$emit(default_data.event, result)
        // 默认变化通知
        this.informDefaultchangeFun(default_data);
      },
      /**
       * 获取粘贴的数据
       */
      getPasteDataFun() {
        let {
          valueText
        } = this;
        // let arr = event.split(/(\n|\r)/);
        console.log('获取粘贴的数据 valueText == ', valueText);
        let enter = /(\n+|\r+)/; // 回车
        let blank = /\s+/; // 空格
        let not_enter_blank = /(\S+|^\r)/; // 非换行非空格
        let arr = valueText.split(enter);
        let result = []; // 结果
        console.log('获取粘贴的数据 arr == ', arr);
        if (arr.length > 0) {
          arr.forEach(item => {
            if (item && not_enter_blank.test(item)) {
              let data = item.split(blank);
              result.push(data);
            }
          })
        }
        if (result.length > 0) {
          result.forEach(item => {
            for (let i = 0; i < item.length; i++) {
              let citem = item[i];
              if (!citem && !not_enter_blank.test(citem)) {
                item.splice(i, 1);
              }
            }
          })
        }
        console.log('获取粘贴的数据 result == ', result);

        if (result.length <= 0) {
          this.myMessage({
            message: '粘贴内容不能为空！'
          })
          return [];
        } else {
          for (let i = 0; i < result.length; i++) {
            let result_item = result[i];
            if (result_item.length > 7) {
              this.myMessage({
                message: '粘贴格式错误！'
              })
              return [];
            }
          }
        }

        // 识别身份
        let data = this.identifyPeopleFun(result);
        // let data = this.identifyPeopleTableModeFun(result);
        console.log('识别身份 data == ', data);
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let data_item = data[i];
            // console.log('识别身份 data_item == ',data_item);
            // console.log('识别身份 !data_item.code || !data_item.name == ',(!data_item.code || !data_item.name));
            // if(!data_item.code || !data_item.name) {
            //   this.myMessage({
            //     message: '粘贴格式错误！'
            //   })
            //   return [];
            // }
          }
        }
        return data;
      },
      /**
       * 识别身份
       */
      identifyPeopleFun(arr = [], callback) {
        console.log('识别身份 arr == ', arr)
        // let result = {
        //   name: '哈姆雷特', // 姓名
        //   type: '1', // 证件类型 1身份证
        //   code: '410711197006031015', // 证件号码
        //   sex: 0, // 性别【0未知，1男，2女】
        //   birth: '', // 出生年月日（格式：yyyy-mm-dd）
        //   tel: '13554468468', // 电话
        // }
        let result = []; // 结果
        if (arr.length > 0) {
          arr.forEach((item, ind) => {
            let json = {};
            let is_error = false;

            // 优先确定-证件类型
            for (let pInd = 0; pInd < item.length; pInd++) {
              let pItem = item[pInd];
              // console.log('优先确定 pItem == ',pItem)
              // 证件类型
              if (!json['type']) {
                if (pItem == '身份证') {
                  json['type'] = 1;
                } else if (pItem == '护照') {
                  json['type'] = 2;
                }
                // else if (pItem == '军官证') {
                //   json['type'] = 3;
                // } else if (pItem == '港澳通行证') {
                //   json['type'] = 4;
                // } else if (pItem == '台湾通行证') {
                //   json['type'] = 5;
                // }
              }
            }
            console.log('优先确定 json[type] == ', json['type'])

            item.forEach((citem, cind) => {
              // 证件类型
              // if(!json['type']) {
              //   if(citem == '身份证') {
              //     json['type'] = 1;
              //   } else if(citem == '护照') {
              //     json['type'] = 2;
              //   }
              // }

              // 生日
              if (!json['birth']) {
                if ((/\d{4}-\d{2}-\d{2}/).test(citem)) {
                  json['birth'] = citem;
                }
              }

              // 性别
              if (!json['sex']) {
                if (citem == '男') {
                  json['sex'] = 1;
                } else if (citem == '女') {
                  json['sex'] = 2;
                }
              }

              // 电话号码
              if (!json['tel']) {
                if (this.REGEXP.is_phone.test(citem)) {
                  json['tel'] = citem;
                }
              }

              // 证件号码
              if (!json['code']) {
                if (citem.length == 18) { // 只有身份转为大写
                  citem = citem.toUpperCase(); // 转换大写
                }
                if (this.myChecktool.identityCodeValid2(citem)) {
                  json['code'] = citem;
                  // 证件类型
                  if (!json['type']) {
                    json['type'] = 1;
                  }
                } else {

                  let {
                    certTypeOptions
                  } = this;
                  console.log('certTypeOptions == ', certTypeOptions)
                  console.log('citem == ', citem)
                  console.log('json[type] == ', json['type'])
                  // 证件类型
                  if (json['type']) { // 如果规定了证件类型，则寻找该类型对应的证件校验
                    let index = certTypeOptions.options.findIndex(item => item.id == json['type']);
                    let item_data = certTypeOptions.options[index];
                    console.log('item_data == ', item_data)
                    if (item_data && item_data.check == 1 && item_data.regular) {
                      let regexp = new RegExp(item_data.regular);
                      console.log('regexp == ', regexp)
                      let upperCase_citem = citem.toUpperCase(); // 转换大写
                      console.log('upperCase_citem == ', upperCase_citem)
                      let regular = (regexp.test(upperCase_citem));

                      if (regular) { // 校验
                        citem = upperCase_citem; // 转换大写
                        console.log('转换大写 citem == ', citem)
                        json['code'] = citem;
                      }
                    }

                  } else { // 未定义类型，则寻找类型和对应的证件校验
                    for (let i = 0; i < certTypeOptions.options.length; i++) {
                      let item_data = certTypeOptions.options[i];
                      console.log('item_data == ', item_data)
                      if (item_data && item_data.check == 1 && item_data.regular) {
                        let regexp = new RegExp(item_data.regular);
                        console.log('regexp == ', regexp)
                        let regular = regexp.test(citem);
                        console.log('regular == ', regular)

                        if (regular) { // 校验
                          citem = citem.toUpperCase(); // 转换大写
                          console.log('转换大写 citem == ', citem)
                          json['code'] = citem;
                          json['type'] = item_data.id;
                          break;
                        }
                      }
                    }
                  }

                  // if (this.REGEXP.is_passport.test(citem) && !this.REGEXP.is_phone.test(citem)) { // 护照
                  //   json['code'] = citem;
                  //   // 证件类型
                  //   if (!json['type']) {
                  //     json['type'] = 2;
                  //   }
                  // } else if (citem.length > 4) {
                  //   if (this.myChecktool.identityCodeValid(citem)) {
                  //     json['code'] = citem;
                  //     // 证件类型
                  //     if (!json['type']) {
                  //       json['type'] = 1;
                  //     }
                  //   } else if ((/^[0-9a-zA-Z]+$/).test(citem)) {
                  //     json['code'] = citem;
                  //     // 证件类型
                  //     // if (!json['type']) {
                  //     //   json['type'] = 2;
                  //     // }
                  //   }
                  // }

                }
              }

              // 名称
              if (citem != '身份证' && citem != '护照' && citem != '军官证' && citem != '港澳通行证' && citem != '台湾通行证' &&
                citem != '男' && citem != '女') {
                if (this.myChecktool.checkTextNameNotRestChars(citem)) {
                  // json['name'] = citem;
                  if (json['name']) {
                    json['name'] += citem;
                  } else {
                    json['name'] = citem;
                  }
                }
              }
            })
            result.push(json);
          })
        }
        // 识别身份
        console.log('识别身份 result == ', result)

        if (callback) {
          callback(result);
        }
        return result;
      },
      /**
       * 识别身份-表格模式
       */
      identifyPeopleTableModeFun(arr = [], callback) {
        console.log('识别身份-表格模式 arr == ', arr)

        let {
          certTypeOptions
        } = this;
        console.log('certTypeOptions == ', certTypeOptions)

        // let result = {
        //   name: '哈姆雷特', // 姓名
        //   type: '1', // 证件类型 1身份证
        //   code: '410711197006031015', // 证件号码
        //   sex: 0, // 性别【0未知，1男，2女】
        //   birth: '', // 出生年月日（格式：yyyy-mm-dd）
        //   tel: '13554468468', // 电话
        // }
        let result = []; // 结果
        if (arr.length > 0) {
          arr.forEach((item, ind) => {

            let json = {};

            let r_name = item[0]; // 名称
            let r_type = item[1]; // 证件类型
            let r_code = item[2]; // 证件号码
            let r_sex = item[3]; // 性别【0未知，1男，2女】
            let r_birth = item[4]; // 出生年月日
            let r_tel = item[5]; // 电话

            // 名称
            if (r_name) {
              json['name'] = r_name;
            }

            // 证件类型
            if (r_type) {
              let index = certTypeOptions.options.findIndex(item => item.name == r_type);
              let item_data = certTypeOptions.options[index];
              console.log('item_data == ', item_data)
              if (item_data) {
                json['type'] = item_data.id;
              } else {
                // 证件类型
                if (r_type == '身份证') {
                  json['type'] = 1;
                } else if (r_type == '护照') {
                  json['type'] = 2;
                } else if (r_type == '军官证') {
                  json['type'] = 3;
                } else if (r_type == '港澳通行证') {
                  json['type'] = 4;
                } else if (r_type == '台湾通行证') {
                  json['type'] = 5;
                } else if (r_type == '其他') {
                  json['type'] = 6;
                }
              }
            }

            // 证件号码
            if (r_code) {
              if (r_code.length == 18) { // 只有身份转为大写
                r_code = r_code.toUpperCase(); // 转换大写
              }
              if (this.myChecktool.identityCodeValid2(r_code)) {
                // 证件类型
                json['type'] = 1;
              }

              json['code'] = r_code;

              // 证件类型
              if (r_type) {
                let index = certTypeOptions.options.findIndex(item => item.name == r_type);
                let item_data = certTypeOptions.options[index];
                console.log('item_data == ', item_data)
                if (item_data && item_data.check == 1 && item_data.regular) {
                  let regexp = new RegExp(item_data.regular);
                  console.log('regexp == ', regexp)
                  let upperCase_code = r_code.toUpperCase(); // 转换大写
                  console.log('upperCase_code == ', upperCase_code)
                  let regular = (regexp.test(upperCase_code));

                  if (regular) { // 校验
                    json['type'] = item_data.id;
                  }
                }
              }

            }

            // 性别
            if (r_sex && !json['sex']) {
              if (r_sex == '男') {
                json['sex'] = 1;
              } else if (r_sex == '女') {
                json['sex'] = 2;
              }
            }

            // 生日
            if (r_birth && !json['birth']) {
              if ((/\d{4}-\d{2}-\d{2}/).test(r_birth)) {
                json['birth'] = r_birth;
              }
            }

            // 电话号码
            if (r_tel) {
              if (this.REGEXP.is_phone.test(r_tel)) {
                json['tel'] = r_tel;
              }
            }


            console.log('解析身份证 json == ', json)


            // let json = {
            //   name: r_name, // 姓名
            //   type: r_type, // 证件类型 1身份证
            //   code: r_code, // 证件号码
            //   sex: r_sex, // 性别【0未知，1男，2女】
            //   birth: r_birth, // 出生年月日（格式：yyyy-mm-dd）
            //   tel: r_tel, // 电话
            // }

            result.push(json);
          })
        }
        // 识别身份
        console.log('识别身份 result == ', result)

        if (callback) {
          callback(result);
        }
        return result;
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .paste-container {
    box-sizing: border-box;

    &::v-deep {
      .el-dialog__header {
        display: none;
      }

      .el-dialog--center .el-dialog__body {
        padding: 46px 55px;
      }
    }
  }

  .paste-body {
    .paste-title {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 18px;
      color: #444343;
    }

    .paste-main {
      .rule {
        display: flex;
        line-height: 1.8;
        font-size: 14px;
        color: #787878;

        .key {}

        .val {
          flex: 1;
          min-width: 0;
        }
      }

      .form-module {
        &.margin-top {
          margin-top: 20px;
        }

        .fm-itme {
          width: 33.33%;

          &.width-aotu {
            width: auto;

            .fm-val {
              .field-item .input-box {
                border: none;
                max-width: none;
                width: auto;
                height: auto;
              }
            }
          }

          // &:first-of-type{
          //   margin-top: 45px;
          // }
          .fm-val {
            .field-item .input-box {
              display: flex;
              max-width: auto;
              height: auto;
              border: none;
              border-radius: initial;
              background: #fff;
            }
          }
        }
      }

      .control {
        box-sizing: border-box;
        padding-right: 17px;
        margin-top: 46px;
        display: flex;
        justify-content: flex-end;

        .item {
          margin-left: 30px;
          min-width: 100px;

          &::v-deep {
            .fake-btn {
              width: 100%;
              height: 36px;
              padding: 0 15px;
              font-size: 18px;
              color: #fff;
              border: 1px solid #E62129;
              background: #E62129;
              outline: none;

              &.el-button:hover,
              &.el-button:focus,
              &.el-button:active {
                color: none;
                border-color: none;
                background-color: none;
              }

              &.el-button:active {
                color: $active-default-color;
                border-color: $active-default-color;
                background-color: #fff;
              }
            }
          }

          &:first-of-type {
            margin-left: 0;
          }

          &.confirm {
            &::v-deep {
              .fake-btn {
                color: #fff;
                border: 1px solid #E62129;
                background: #E62129;
              }
            }
          }

          &.cancel {
            &::v-deep {
              .fake-btn {
                color: #fff;
                border: 1px solid #CCCCCC;
                background: #CCCCCC;
              }
            }
          }
        }
      }
    }
  }

</style>
