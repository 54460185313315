<template>
  <div class="my-swiper-container">
    <swiper class="swiper-place" ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item,index) in mapOptions" :key="index">
        <div class="content">
          <div class="title">
            <el-tooltip effect="dark" content="" placement="top">
              <div slot="content">
                <p>{{item.text}}</p>
              </div>
              <div class="title-text">
                <span>{{item.limit_text}}</span>
                <i class="iconfont icon-arrow-down-1-icon"></i>
              </div>
            </el-tooltip>
          </div>
          <p class="price">{{item.value}}</p>
        </div>
      </swiper-slide>
      <template v-if="mapOptions.length > 10">
        <div class="swiper-button-prev" slot="button-prev" @click="prev"></div>
        <div class="swiper-button-next" slot="button-next" @click="next"></div>
      </template>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

export default {
  // 允许组件模板递归地调用自身
  name: 'SwperTemp',
  // 声明一组可用于组件实例中的组件
  components: {
    Swiper,
    SwiperSlide,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    options: {
      type: Array,
      default: () => [],
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      mapOptions: this.options,
      // 轮播图选项
      swiperOptions: {
        slidesPerView : 10, // 根据slide的宽度自动调整展示数量。此时需要设置slide的 style 宽度
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    }
  },
  // 计算属性：
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  // 钩子函数--侦听data变动：
  watch: {
    options: function(e) {
      this.mapOptions = e;
      // 初始化选项数据
      this.initOptionsFun();
    }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
    /**
     * 初始化选项数据
     */
    initOptionsFun() {
      const _this = this;
      let { mapOptions } = this;
      this.mapOptions = mapOptions.map(item => {
        // 限制字符串长度
        let limit_text = _this.limitStringLengthFun(item.text);
        item.limit_text = limit_text;
        return item;
      })

      // 测试数据
      // let new_arr = mapOptions.map(item => {
      //   // 限制字符串长度
      //   let limit_text = _this.limitStringLengthFun(item.text);
      //   item.limit_text = limit_text;
      //   return item;
      // })
      // let test_arr = [];
      // for(let i = 0; i < 10; i++) {
      //   test_arr.push({
      //     limit_text: i,
      //     value: i,
      //   })
      // }
      // this.mapOptions = new_arr.concat(test_arr);
      // console.log('this.mapOptions == ',this.mapOptions)
    },
    /**
     * 限制字符串长度
     */
    limitStringLengthFun(text) {
      if(text.length > 3) {
        return text.slice(0,3) + '...';
      } else {
        return text;
      }
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(3, 1000, false)
    // 初始化选项数据
    this.initOptionsFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.my-swiper-container {
  $height: 100px;
  box-sizing: border-box;
  .swiper-place {

    position: relative;
    // &:hover {
    //   .swiper-button-prev, .swiper-button-next {
    //     display: initial;
    //   }
    // }
    // .swiper-button-prev, .swiper-button-next {
    //   display: none;
    // }
  }
  &::v-deep {
    .swiper-container {
      width: 100%;
      overflow: hidden;
    }
    .swiper-slide{
      width: auto; /*根据内容调整宽度*/
    }
    .swiper-button-prev, .swiper-button-next {
      $button-height: 24px;
      // transform: translateY(12px);
      top: calc(((#{$height} - #{$button-height} ) / 2) + (#{$button-height} / 2 / 2));
      width: $button-height;
      height: $button-height;
      line-height: $button-height;
      border-radius: 50%;
      background: #d7d7d7;
      &:after {
        font-size: 12px;
        color: #fff;
      }
    }
    .swiper-button-prev {
      left: 3px;
    }
    .swiper-button-next {
      right: 3px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $height;
    text-align: center;
    .title {
      color: #787878;
      .title-text {
        display: inline-block;
        font-size: 14px;
        .iconfont {
          font-size: 12px;
        }
      }
    }
    .price {
      margin-top: 18px;
      font-size: 18px;
      color: #444343;
    }
  }
}
</style>
