<template>
  <div class="dialog-container">
    <el-dialog width="736px" top="10vh" center :visible.sync="mapVisible" :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="dialog-body">
        <div class="dialog-title">
          <h3 class="title-text">添加人员</h3>
        </div>
        <div class="dialog-main">
          <div class="form-module">
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">名称：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.name" placeholder="请输入" @input="inputChangeFun($event,'name')">
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">证件类型：</div>
              </div>
              <div class="fm-val">
                <div class="select-box">
                  <el-select v-model="formData.type" placeholder="请选择">
                    <!-- <el-option label="身份证" value="1"></el-option>
                    <el-option label="护照" value="2"></el-option>
                    <el-option label="军官证" value="3"></el-option>
                    <el-option label="港澳通行证" value="4"></el-option>
                    <el-option label="台湾通行证" value="5"></el-option> -->
                    <el-option :label="item.name" :value="item.id" v-for="(item,index) in certTypeOptions.options"
                      :key="index"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">证件号码：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box" v-if="formData.type == 1">
                    <el-input v-model="formData.code" maxlength="18" placeholder="请输入"
                      @input="inputChangeFun($event,'code')"></el-input>
                  </div>
                  <div class="input-box" v-else-if="formData.type == 2">
                    <el-input v-model="formData.code" maxlength="9" placeholder="请输入"
                      @input="inputChangeFun($event,'code')"></el-input>
                  </div>
                  <div class="input-box" v-else>
                    <el-input v-model="formData.code" placeholder="请输入" @input="inputChangeFun($event,'code')">
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme" v-if="formData.type != 1">
              <div class="fm-key">
                <div class="key-title required-style diff">性别：</div>
              </div>
              <div class="fm-val">
                <div class="select-box">
                  <el-select v-model="formData.sex" placeholder="请选择">
                    <el-option label="未知" value="0" :disabled="true"></el-option>
                    <el-option label="男" value="1"></el-option>
                    <el-option label="女" value="2"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="fm-itme" v-if="formData.type != 1">
              <div class="fm-key">
                <div class="key-title required-style diff">出生日期：</div>
              </div>
              <div class="fm-val">
                <div class="picker-date">
                  <el-date-picker v-model="formData.birth" type="date" placeholder="请选择" value-format="yyyy-MM-dd"
                    @change="function(e) {formData.birth = e}">
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">手机号码：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.tel" maxlength="11" placeholder="请输入"
                      @input="inputChangeFun($event,'tel')"></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-module">
            <div class="fm-rest">
              <div class="fm-control">
                <div class="control-item">
                  <el-button class="fake-btn" @click="clickSubmitBtnFun">
                    <span>提交</span>
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // 身份证校验函数
  import Idcard from '@/utils/my_idcard.js';
  // 函数库
  import {
    deepClone, // 深拷贝
  } from '@/utils/utils_fun.js';
  // 添加人员
  export default {
    // 允许组件模板递归地调用自身
    name: 'add-personnel-dialog',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Object,
        default: () => {
          let formData = {
            name: '', // 姓名
            type: '1', // 证件类型 1身份证
            code: '', // 证件号码
            sex: '', // 性别【0未知，1男，2女】
            birth: '', // 出生年月日（格式：yyyy-mm-dd）
            tel: '', // 电话
          }
          return formData;
        }
      },
      type: { // 1/添加 2/编辑
        type: [String, Number],
        default: 1,
      },
      certType: {
        type: Array,
        default: () => {
          // value: [
          //   {id: "1", name: "身份证"},
          //   {id: "2", name: "护照"},
          //   {id: "3", name: "军官证"},
          //   {id: "4", name: "港澳通行证"},
          //   {id: "5", name: "台湾通行证"},
          //   {id: "6", name: "其它"},
          // ],
          return [];
        }
      },
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        is_submit: false, // 是否在提交
        mapVisible: this.visible,
        formData: this.options,
        certTypeOptions: {
          options: [],
          value: '',
          index: '',
        }
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      type: function (e) {
        console.log('侦听 type e == ', e)
      },
      visible: function (e) {
        this.mapVisible = e;
        // // 初始化页面数据
        this.initPageDataFun();

      },
      options: function (e) {
        console.log('options e == ', e)
        this.formData = e;
        if (this.formData.type) {
          this.formData.type = String(this.formData.type);
        }

        console.log('options e == ', this.options)
        console.log('options == ', this.options)
        console.log('options formData == ', this.formData)

        // 初始化编辑数据
        this.initEditorDataFun().then(() => {
          // 初始化页面数据
          // this.initPageDataFun();
        })

      },
      certType: function (e) {
        let index = 0;
        this.certTypeOptions.options = e;
        this.certTypeOptions.value = e[index];
        this.certTypeOptions.index = index;

        // 初始化编辑数据
        this.initEditorDataFun().then(() => {
          // 初始化页面数据
          // this.initPageDataFun();
        })
      },

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 初始化编辑数据
       */
      initEditorDataFun() {
        return new Promise((resolve, reject) => {
          let {
            formData,
            certTypeOptions
          } = this;
          if (certTypeOptions.options.length > 0) {
            let type = formData.type;
            let index = certTypeOptions.options.findIndex(item => item.id == type);
            console.log('初始化编辑数据 formData == ', formData)
            console.log('初始化编辑数据 index == ', index)
            if (index == -1) {
              index = 0;
            }
            let item_data = certTypeOptions.options[index];
            this.certTypeOptions.value = item_data;
            this.certTypeOptions.index = index;
            this.formData.type = String(item_data.id);
          }
          resolve();
        })
      },
      /**
       * 初始化页面数据
       */
      initPageDataFun() {
        this.formData = {
          name: '', // 姓名
          type: '1', // 证件类型 1身份证
          code: '', // 证件号码
          sex: '', // 性别【0未知，1男，2女】
          birth: '', // 出生年月日（格式：yyyy-mm-dd）
          tel: '', // 电话
        };
        // 锁
        this.is_submit = false;
      },
      /**
       * 初始化页面数据2
       */
      initPageDataFun2() {
        this.formData = {
          name: '哈姆雷特', // 姓名
          type: '1', // 证件类型 1身份证
          code: '410711197006031015', // 证件号码
          sex: '0', // 性别【0未知，1男，2女】
          birth: '', // 出生年月日（格式：yyyy-mm-dd）
          tel: '13554468468', // 电话
        };
      },
      /**
       * 输入框监听变化
       */
      inputChangeFun(event, name) {
        console.log(event)
        console.log(name)
        console.log(this.formData)
      },
      /**
       * 关闭弹窗前
       */
      dialogBeforeCloseFun(done) {
        done();
        console.log('关闭弹窗前 == ', this.mapVisible)
        // 告知弹窗变化
        this.informChangeFun();
      },
      /**
       * 告知弹窗变化
       */
      informChangeFun(opt) {
        let default_data = {
          visible: false,
          type: this.type, // 1/添加 2/编辑
          status: 3, // 1/成功 2/失败 3/取消
          message: '取消',
          data: '',
        };
        let result = Object.assign(default_data, opt);
        this.$emit('on-change', result)
      },
      /**
       * 类别变换
       */
      categoryChangeFun(e) {
        console.log('类别变换 == ', e)
        console.log('类别变换 this.category_options == ', this.category_options)
      },
      /**
       * 设置当前页面缓存
       */
      setAtPageSessionFun(val) {
        this.mySetSession(this.$options.name, val)
      },
      /**
       * 获取当前页面缓存
       */
      getAtPageSessionFun(callback) {
        let result = this.myGetSession(this.$options.name);
        if (callback) {
          callback(result);
        }
        return result;
      },
      /**
       * 移除当前页面缓存
       */
      removeAtPageSessionFun() {
        this.myRemoveSession(this.$options.name)
      },
      /**
       * 点击提交按钮
       */
      clickSubmitBtnFun() {

        // 校验数据
        let flag = this.verifyDataFun();
        if (!flag) return;

        // 发起录入请求
        this.sendEnteringRequestFun().then(res => {
          // 锁(不能连续点点击)
          if (this.is_submit) return;
          this.is_submit = true;
          let result = [res];
          // 发起录入后
          this.sendEnteringAfterFun(result);
        });
      },
      /**
       * 发起录入请求
       */
      sendEnteringRequestFun(formData) {
        const _this = this;
        formData = formData || this.formData;
        console.log('发起录入 formData == ', formData);
        return new Promise((resolve, reject) => {
          resolve(formData);
        })
      },
      /**
       * 发起录入后
       */
      sendEnteringAfterFun(data) {
        let message = this.type == 1 ? '新建' : '修改';
        this.myMessage({
          type: 'success',
          message: `${message}成功！`,
        })
        // 告知弹窗变化
        this.informChangeFun({
          visible: false,
          status: 1, // 1/成功 2/失败 3/取消
          message: '成功',
          data: data,
        });
        // 初始化页面 data 数据
        this.initPageDataFun();
      },
      /**
       * 快捷-发起录入请求
       */
      _shortcutSendEnteringAfterFun(form, opt) {
        let copy_data = deepClone(form);
        let default_opt = {
          is_rectify: false, // 是否矫正（用于粘贴名单手动输入的：性别、生日与身份证号码不匹配时，已身份证为准）
        }

        Object.assign(default_opt, opt);

        // 是否矫正
        if (default_opt.is_rectify) {
          copy_data = this.rectifyDataFun(copy_data); // 矫正数据
          console.log('矫正数据 == ', copy_data)
        }

        // 发起录入请求
        return this.sendEnteringRequestFun(copy_data);
        // .then((res) => {
        //   console.log('录入请求成功 res == ',res);
        //   let resData = res.data.data;

        // }).catch((err) => {

        // });
      },
      /**
       * 矫正数据
       */
      rectifyDataFun(arr = []) {
        if (arr.length == 0) return [];
        let idcard = new Idcard();
        return arr.map(item => {
          let {
            code
          } = item;
          // 是否是身份证
          if (code && this.myChecktool.identityCodeValid2(code)) {
            let birth = idcard.getBirthdate(code); // 获取生日
            let sex = idcard.getSex(code); // 获取性别
            item['birth'] = birth;
            item['sex'] = sex;
            item['type'] = 1;
          }
          return item;
        })
      },
      /**
       * 校验数据
       */
      verifyDataFun() {
        let form_data = this.formData;

        // name: '哈姆雷特', // 姓名
        // type: '1', // 证件类型 1身份证
        // code: '410711197006031015', // 证件号码
        // sex: 1, // 性别【0未知，1男，2女】
        // birth: '1970-06-03', // 出生年月日（格式：yyyy-mm-dd）
        // tel: '13554468468', // 电话

        // 名称
        if (!form_data.name) {
          this.myMessage({
            message: '请输入名称!'
          });
          return false;
        } else if (!this.myChecktool.checkTextNameNotRestChars(form_data.name)) {
          this.myMessage({
            message: '名称不能存在数字或特殊字符!'
          });
          return false;
        } else if (form_data.name.length < 2) {
          this.myMessage({
            message: '名称不能少于两个字符!'
          });
          return false;
        }

        // 证件类型
        if (!form_data.type) {
          this.myMessage({
            message: '请选择证件类型!'
          });
          return false;
        }

        // 证件号码
        // if(!form_data.code || !this.REGEXP.is_idcard.test(form_data.code)) {
        //   this.myMessage({
        //     message: '请输入正确的证件号码!'
        //   });
        //   return false;
        // } else {
        //   // 解析身份证
        //   this.analysisIdentityCardFun(form_data.code);
        // }

        // 证件号码
        if (!form_data.code) {
          this.myMessage({
            message: '请填写证件号码!'
          });
          return false;
        } else {

          if (form_data.type == 1) { // 身份证
            if (!this.myChecktool.identityCodeValid2(form_data.code)) {
              this.myMessage({
                message: '请输入正确格式的身份证号码!'
              });
              return false;
            } else {
              // 解析身份证
              this.analysisIdentityCardFun(form_data.code);
            }
          } else {

            let {
              certTypeOptions
            } = this;
            let index = certTypeOptions.options.findIndex(item => item.id == form_data.type);
            let item_data = certTypeOptions.options[index];
            console.log('item_data == ', item_data)
            if (item_data.check == 1 && item_data.regular) {
              let regexp = new RegExp(item_data.regular);
              console.log('regexp == ', regexp)
              let regular = !(regexp.test(form_data.code));

              if (regular) { // 校验
                this.myMessage({
                  message: `请输入正确格式的【${item_data.name}】证件号码`
                });
                return false;
              }
            }

            // if (form_data.type == 2 && item_data.check == 1) { // 护照
            //   if (!this.REGEXP.is_passport.test(form_data.code)) {
            //     this.myMessage({
            //       message: '请输入正确格式的护照号码!'
            //     });
            //     return false;
            //   }
            // } else if(form_data.type == 3 && item_data.check == 1) {
            //   if(!this.REGEXP.is_certificate_officers.test(form_data.code)) { // 军官证
            //     app.myMessage({
            //       message: '请输入正确格式的军官证!'
            //     });
            //     return false;
            //   }
            // } else if(form_data.type == 4 && item_data.check == 1) {
            //   if(!this.REGEXP.is_hongkong_pass.test(form_data.code)) { // 港澳通行证
            //     this.myMessage({
            //       message: '请输入正确格式的港澳通行证，且字母需大写!'
            //     });
            //     return false;
            //   }
            // } else if(form_data.type == 5 && item_data.check == 1) {
            //   if(!this.REGEXP.is_formosa_pass.test(form_data.code)) { // 台湾通行证
            //     this.myMessage({
            //       message: '请输入正确格式的台湾通行证!'
            //     });
            //     return false;
            //   }
            // }
            // else {
            //   if(!this.REGEXP.is_number_letter.test(form_data.code)) {
            //     this.myMessage({
            //       message: '请输入英文或数字证件号码!'
            //     });
            //     return false;
            //   }
            // }
          }
        }

        if (form_data.type > 1) {
          // 性别
          if (!form_data.sex) {
            this.myMessage({
              message: '请选择性别!'
            });
            return false;
          }
          // 出生日期
          if (!form_data.birth) {
            this.myMessage({
              message: '请选择出生日期!'
            });
            return false;
          }
        }

        // // 咨询电话
        // if(!form_data.tel || !this.REGEXP.is_phone.test(form_data.tel)) {
        //   this.myMessage({
        //     message: '请添加有效的电话!'
        //   });
        //   return false;
        // }

        return true;
      },
      /**
       * 解析身份证
       */
      analysisIdentityCardFun(code) {
        let idcard = new Idcard();
        let birth = idcard.getBirthdate(code); // 获取生日
        let sex = idcard.getSex(code); // 获取性别
        console.log('birth == ', birth)
        console.log('sex == ', sex)
        this.formData.birth = birth;
        this.formData.sex = sex;
      }
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前
      // 移除当前页面缓存
      this.removeAtPageSessionFun();
    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .dialog-container {
    box-sizing: border-box;
  }

  .dialog-body {
    .dialog-title {
      display: flex;
      align-items: center;

      .title-text {
        font-weight: 400;
        font-size: 18px;
        color: #444343;
      }

      .title-text2 {
        margin-left: 24px;
        cursor: pointer;
        text-decoration: underline;
        font-size: 12px;
        color: #E62129;
      }
    }

    .form-module {
      .fm-itme {
        &:first-of-type {
          margin-top: 45px;
        }

        .fm-val {
          .picker-date {
            &::v-deep {

              .el-date-editor.el-input,
              .el-date-editor.el-input__inner {
                width: 100%;
              }
            }
          }

          .picker-date,
          .select-box,
          .cascader-box {
            max-width: 353px;
          }
        }
      }

      .fm-rest {
        .fm-control {
          margin-top: 68px;
        }
      }
    }
  }

</style>
