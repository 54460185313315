<template>
  <div class="dialog-container">
    <el-dialog
      width="736px"
      :top="formData.type == 1 ? '10vh' : '4vh'"
      center
      :visible.sync="mapVisible"
      :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="dialog-body">
        <div class="dialog-title">
          <h3 class="title-text">
            <span v-if="formData.id">修改</span>
            <span v-else>添加</span>
            <span>投保人信息</span>
          </h3>
          <el-tooltip effect="dark" content="" placement="top">
            <div slot="content">
              <p>只可开投保人抬头为公司名称的发票</p>
            </div>
            <span class="title-text2">开票必看</span>
          </el-tooltip>
        </div>
        <div class="dialog-main">
          <div class="form-module">
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">名称：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.name" placeholder="请输入" @input="inputChangeFun($event,'name')"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">投保类型：</div>
              </div>
              <div class="fm-val">
                <div class="select-box">
                  <el-select v-model="formData.type" placeholder="请选择" @change="function(e) { formData.certificate_type = e}">
<!--                    <el-option label="个人" value="1"></el-option>-->
                    <el-option label="企业" value="2"></el-option>
                  </el-select>
                </div>
                <!-- <div class="cascader-box">
                  <el-cascader :options="[{value: '1', label: '个人', children: [{value:'2',label: '单位'}],}]" placeholder="请选择" :show-all-levels="false" @change="categoryChangeFun"></el-cascader>
                </div> -->
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">证件类型：</div>
              </div>
              <div class="fm-val">
                <div class="select-box">
                  <el-select v-model="formData.certificate_type" placeholder="请选择">
                    <el-option label="身份证" value="1" v-if="formData.type == 1"></el-option>
                    <el-option label="统一社会信用代码" value="2" v-if="formData.type == 2"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">证件号码：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.certificate_code" maxlength="18" placeholder="请输入" @input="inputChangeFun($event,'certificate_code')"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme" v-if="formData.type == 2">
              <div class="fm-key">
                <div class="key-title required-style">企业单位性质：</div>
              </div>
              <div class="fm-val">
                <div class="select-box">
                  <el-select v-model="formData.company_property" placeholder="请选择">
                    <el-option
                      v-for="(item,index) in natureOptions" :key="index"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="fm-itme" v-if="formData.type == 2">
              <div class="fm-key">
                <div class="key-title required-style">企业经营地址：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input readonly v-model="formData.company_address" placeholder="请选择" @input="inputChangeFun($event,'company_address')"></el-input>
                    <div class="location-icon" @click.stop="location_visivle = !location_visivle">
                      <i class="iconfont icon-dingwei"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme" v-if="formData.type == 2">
              <div class="fm-key">
                <div class="key-title required-style diff">联系人姓名：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.compellation" placeholder="请输入" @input="inputChangeFun($event,'compellation')"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">手机号码：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.tel" maxlength="11" placeholder="请输入" @input="inputChangeFun($event,'tel')"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">电子邮箱：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.email" placeholder="请输入" @input="inputChangeFun($event,'email')"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">是否默认投保人：</div>
              </div>
              <div class="fm-val">
                <div class="select-box">
                  <el-select v-model="formData.is_default" placeholder="请选择">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-module">
            <div class="fm-rest">
              <div class="fm-control">
                <div class="control-item">
                  <el-button class="fake-btn" @click="clickSubmitBtnFun">
                    <span>提交</span>
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 地图定位弹窗 -->
    <my-bmap-location :visible="location_visivle" @on-change="selectedlocationFun" />

  </div>
</template>

<script>
import myBmapLocation from '@/components/my-bmap-location/MyBmapLocation.vue';
export default {
  // 允许组件模板递归地调用自身
  name: 'add-applicant-dialog',// 添加被保人
  // 声明一组可用于组件实例中的组件
  components: {
    myBmapLocation,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        let formData = {
          id: '', //	否	string	投保人id（注：编辑是为必填参数）
          name: '', //	是	string	名称
          type: '2', //	是	string	投保类型【1个人，2单位】
          certificate_type: '2', //	是	string	证件类型【1个人，2单位】
          certificate_code: '', //	是	string	证件号码
          tel: '', //	是	string	手机号码
          email: '', //	是	string	邮箱
          is_default: '1', //	否	string	是否为默认投保人【0否，1是】
          compellation: '', //	是	string	联系人姓名
          company_property: '', //	是	string	企业性质（投保类型为单位时为必填项）
          company_address: '', //	是	string	企业经营地址（投保类型为单位时为必填项
        }
        return formData;
      },
    },
    type: { // 1/添加 2/编辑
      type: [String,Number],
      default: 1,
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      is_submit: false, // 是否在提交
      mapVisible: this.visible,
      natureOptions: ['机关、团体','国家机关','党政机关','社会团体','基层群众自治组织','事业单位','卫生事业','体育事业','社会事业','教育事业','文化艺术业','广播电影电视业','科学研究业','综合技术服务业','企业单位','集体','个体','私有','外资','混合所有制','其他'],
      formData: Object.assign({
        id: '',
        name: '',
        type: '2',
        certificate_type: '2',
        certificate_code: '',
        tel: '',
        email: '',
        is_default: '1',
        compellation: '',
        company_property: '',
        company_address: '',
      },this.options),
      // formData: {
      //   id: '', //	否	string	投保人id（注：编辑是为必填参数）
      //   name: '', //	是	string	名称
      //   type: '1', //	是	string	投保类型【1个人，2单位】
      //   certificate_type: '', //	是	string	证件类型【1个人，2单位】
      //   certificate_code: '', //	是	string	证件号码
      //   tel: '', //	是	string	手机号码
      //   email: '', //	是	string	邮箱
      //   is_default: '1', //	否	string	是否为默认投保人【0否，1是】
      //   compellation: '', //	是	string	联系人姓名
      //   company_property: '', //	是	string	企业性质（投保类型为单位时为必填项）
      //   company_address: '', //	是	string	企业经营范围（投保类型为单位时为必填项）
      // },
      location_visivle: false, // 显示地图定位
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    visible: function(e) {
      this.mapVisible = e;
      // 初始化页面 data 数据
      this.initPageDataFun();
    },
    options: function(e) {
      console.log('options e == ',e)
      this.formData = Object.assign(this.formData,e);
      console.log('options e this.formData == ',this.formData)
    },
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 初始化页面数据
     */
    initPageDataFun() {
      this.formData = {
        id: '', //	否	string	投保人id（注：编辑是为必填参数）
        name: '', //	是	string	名称
        type: '2', //	是	string	投保类型【1个人，2单位】
        certificate_type: '2', //	是	string	证件类型【1个人，2单位】
        certificate_code: '', //	是	string	证件号码
        tel: '', //	是	string	手机号码
        email: '', //	是	string	邮箱
        is_default: '1', //	否	string	是否为默认投保人【0否，1是】
        compellation: '', //	是	string	联系人姓名
        company_property: '', //	是	string	企业性质（投保类型为单位时为必填项）
        company_address: '', //	是	string	企业经营范围（投保类型为单位时为必填项）
      };
      // 锁
      this.is_submit = false;
    },
    /**
     * 初始化页面数据2
     */
    initPageDataFun2() {
      this.formData = {
        id: '', //	否	string	投保人id（注：编辑是为必填参数）
        name: '哈迪斯', //	是	string	名称
        type: '1', //	是	string	投保类型【1个人，2单位】
        certificate_type: '1', //	是	string	证件类型【1个人，2单位】
        certificate_code: '410711197006031015', //	是	string	证件号码
        tel: '13654564545', //	是	string	手机号码
        email: 'abc123@163.com', //	是	string	邮箱
        is_default: '0', //	否	string	是否为默认投保人【0否，1是】
        compellation: '企业单位侯杜天', //	是	string	联系人姓名
        company_property: '机关、团体', //	是	string	企业性质（投保类型为单位时为必填项）
        company_address: '建设银行大厦-停车场-深圳市罗湖区金塘街55号', //	是	string	企业经营范围（投保类型为单位时为必填项）
      }
    },
    /**
     * 输入框监听变化
     */
    inputChangeFun(event,name) {
      console.log(event)
      console.log(name)
      console.log(this.formData)
    },
    /**
     * 关闭弹窗前
     */
    dialogBeforeCloseFun(done) {
      done();
      console.log('关闭弹窗前 == ',this.mapVisible)
      // 告知弹窗变化
      this.informChangeFun();
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      let default_data = {
        visible: false,
        status: 3, // 1/成功 2/失败 3/取消
        message: '取消',
        data: '',
      };
      let result = Object.assign(default_data,opt);
      console.log('回调')
      this.$emit('on-change',result)
    },
    /**
     * 类别变换
     */
    categoryChangeFun(e) {
      console.log('类别变换 == ',e)
      console.log('类别变换 this.category_options == ',this.category_options)
    },
    /**
     * 选择地址回调
     */
    selectedlocationFun(e) {
      console.log('选择地址回调 == ',e);
      this.location_visivle = false; // 关闭弹窗
      let { status, data } = e;
      if(status == 1) {
        let { title, province, city, address, point } = data;
        //  distance: '0', //  当前定位与场地地址距离
        //   address: '', // 地点
        //   lng: '',
        //   lat: '',
        //   province: '',
        //   city: '',
        //   county: '',
        // 设置地址
        // this.formData['province'] = province;
        // this.formData['city'] = city;
        // this.formData['lng'] = point.lng;
        // this.formData['lat'] = point.lat;
        this.formData['company_address'] = title + '-' + address;
      }
      console.log('选择地址回调 this.formData == ',this.formData);
    },
    /**
     * 点击提交按钮
     */
    clickSubmitBtnFun() {

      // 校验数据
      let flag = this.verifyDataFun();
      if(!flag) return;

      // 锁(不能连续点点击)
      if(this.is_submit) return;
      this.is_submit = true;

      // 发起录入请求
      this.sendEnteringRequestFun().then((res) => {
        let resData = res.data.data;
        console.log('录入请求成功 res == ',res);
        // 发起录入后
        this.sendEnteringAfterFun();
      }).catch((err) => {
        console.log('录入请求失败 err == ',err)
        // 锁
        this.is_submit = false;
      });
    },
    /**
     * 发起录入请求
     */
    sendEnteringRequestFun(formData) {
      const _this = this;
      formData = formData || this.formData;
      console.log('发起录入请求 查看业务 formData == ',formData);
      return this.myRequest({
        method: 'post',
        url: '/api/insurance/insuredsave',
        data: formData,
      })
      // .then((res) => {
      //   let resData = res.data.data;
      //   console.log('录入请求成功 res == ',res);
      //   // this.mySetStorage({ // 设置本地缓存
      //   //   name: 'active_test',
      //   //   value: this.formData,
      //   //   expires: 60000 * 24 * 31
      //   // });
      //   // 发起录入后
      //   this.sendEnteringAfterFun();
      // }).catch((err) => {
      //   console.log('录入请求失败 err == ',err)
      //   // 锁
      //   this.is_submit = false;
      // })
    },
    /**
     * 发起录入后
     */
    sendEnteringAfterFun(data) {
      let message = '新建成功！';
      // 如果有id，则为修改
      if(this.formData.id){
        message = '修改成功！';
      }
      this.myMessage({
        type: 'success',
        message: message,
      })
      // 告知弹窗变化
      this.informChangeFun({
        visible: false,
        status: 1, // 1/成功 2/失败 3/取消
        message: '成功',
        data: '',
      });
      // 初始化页面 data 数据
      this.initPageDataFun();
    },
    /**
     * 快捷-发起录入请求
     */
    _shortcutSendEnteringAfterFun(form) {
      // 发起录入请求
      return this.sendEnteringRequestFun(form);
      // .then((res) => {
      //   console.log('录入请求成功 res == ',res);
      //   let resData = res.data.data;

      // }).catch((err) => {

      // });
    },
    /**
     * 校验数据
     */
    verifyDataFun() {
      let form_data = this.formData;
      console.log('form_data == ',form_data)

      // formData: {
      //   id: '', //	否	string	投保人id（注：编辑是为必填参数）
      //   name: '', //	是	string	名称
      //   type: '1', //	是	string	投保类型【1个人，2单位】
      //   certificate_type: '', //	是	string	证件类型【1个人，2单位】
      //   certificate_code: '', //	是	string	证件号码
      //   tel: '', //	是	string	手机号码
      //   email: '', //	是	string	邮箱
      //   is_default: '1', //	否	string	是否为默认投保人【0否，1是】
      //   compellation: '', //	是	string	联系人姓名
      //   company_property: '', //	是	string	企业性质（投保类型为单位时为必填项）
      //   company_address: '', //	是	string	企业经营范围（投保类型为单位时为必填项）
      // },

      // 名称
      if(!form_data.name) {
        this.myMessage({
          message: '请输入名称!'
        });
        return false;
      } else if(!this.myChecktool.checkTextNameNotRestChars(form_data.name)) {
        this.myMessage({
          message: '名称不能存在数字或特殊字符!'
        });
        return false;
      } else if(form_data.name.length < 2){
        this.myMessage({
          message: '名称不能少于两个字符!'
        });
        return false;
      }

      // 投保类型
      if(!form_data.type) {
        this.myMessage({
          message: '请输入选择投保类型！'
        });
        return false;
      }

      // 证件类型
      if(!form_data.certificate_type) {
        this.myMessage({
          message: '请选择证件类型!'
        });
        return false;
      }

      // 证件号码
      if(!form_data.certificate_code) {
        this.myMessage({
          message: '请填写证件号码!'
        });
        return false;
      } else {
        if(form_data.certificate_type == 1) {
          if(!this.myChecktool.identityCodeValid2(form_data.certificate_code)) {
            this.myMessage({
              message: '请输入正确格式的身份证号码!'
            });
            return false;
          }
        } else if(form_data.certificate_type == 2){
          if(!this.REGEXP.is_social_credit.test(form_data.certificate_code)) {
            this.myMessage({
              message: '请输入正确格式的统一社会信用代码!'
            });
            return false;
          }
        }
      }

      // 如果是企业类型时
      if(form_data.type == 2) {
        // 联系人姓名
        if(!form_data.compellation) {
          this.myMessage({
            message: '请输入联系人姓名！'
          });
          return false;
        }
      }


      // 咨询电话
      if(!form_data.tel || !this.REGEXP.is_phone.test(form_data.tel)) {
        this.myMessage({
          message: '请添加有效的电话号码!'
        });
        return false;
      }

      // 电子邮箱
      if(!form_data.email || !this.REGEXP.is_email.test(form_data.email)) {
        this.myMessage({
          message: '请输入正确格式的电子邮箱!'
        });
        return false;
      }


      return true;
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
@import '@/views/insurance/css/common.scss';
.dialog-container {
  box-sizing: border-box;
}

.dialog-body {
  .dialog-title {
    display: flex;
    align-items: center;
    .title-text {
      font-weight: 400;
      font-size: 18px;
      color: #444343;
    }
    .title-text2 {
      margin-left: 24px;
      cursor: pointer;
      text-decoration: underline;
      font-size: 12px;
      color: #E62129;
    }
  }
  .form-module {
    .fm-itme {
      &:first-of-type{
        margin-top: 45px;
      }
      .fm-val {
        .select-box,
        .cascader-box {
          max-width: 353px;
        }
      }
    }
    .fm-rest {
      .fm-control {
        margin-top: 68px;
      }
    }
  }
}
</style>
