<template>
  <div class="mpa-container">
    <el-dialog width="900px" title="地图定位" center :visible.sync="mapVisible" :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="map-body">
        <div class="map-main" id="allmap" ref="dom"></div>
        <div class="map-control">
          <div class="map-serach">
            <div class="serach-inp">
              <el-input id="suggestId" v-model="mapKeywords" placeholder="请输入关键字"
                @keyup.enter.native="clickSerachBtnFun()">
              </el-input>
            </div>
            <div class="serach-btn">
              <el-button class="fake-btn" type="primary" @click="clickSerachBtnFun">
                <i class="iconfont icon-sousuo"></i>
              </el-button>
            </div>
          </div>
          <div class="serach-place" id="r-result">
            <ul class="serach-list">
              <li class="cl-item" v-for="(item,index) in serach_list" :key="index" @click="selectedlocationFun(index)">
                <div class="cl-info">
                  <h3 class="cl-title">{{item.title}}</h3>
                  <p class="cl-description">{{item.address}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    deepClone, // 深拷贝
  } from '@/utils/utils_fun.js';
  import BMap from 'BMap'

  // import { ref, onMounted } from 'vue'
  // const dom = ref();
  // let map;
  // onMounted(() => {
  //   map = new window.BMapGL.Map(dom.value);
  //   var point = new window.BMapGL.Point(116.404, 39.915);
  //   map.centerAndZoom(point, 15);
  //   map.enableScrollWheelZoom(true);
  //   map.setMapType(window.BMAP_EARTH_MAP);
  // })

  export default {
    // 允许组件模板递归地调用自身
    name: 'MyBmapLocation',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      keywords: {
        type: String,
        default: '',
      },
      price: {
        type: [String, Number],
        default: '',
      },
      qrCode: {
        type: String,
        default: '',
      },
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        is_loading: false, // 是否装载完成
        mapVisible: this.visible,
        mapKeywords: this.keywords,
        map: null, // 地图对象
        location_info: {}, // 位置信息
        serach_local: null, // 搜索对象
        serach_list: [],
        init_serach: '写字楼$景点$建筑$医院$楼房$电影院$银行$公交站$美食$小区', // 类型

      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      visible: function (e) {
        this.mapVisible = e;
        if (this.mapVisible) {
          // 页面准备完毕
          if (!this.is_loading) {
            this.pageReadyFun();
          }
        }
      },
      keywords: function (e) {
        this.mapKeywords = e;
      }
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 关闭弹窗前
       */
      dialogBeforeCloseFun(done) {
        done();
        console.log('关闭弹窗前 == ', this.mapVisible)
        // 告知弹窗变化
        this.informChangeFun();
      },
      /**
       * 告知弹窗变化
       */
      informChangeFun(opt) {
        let default_data = {
          visible: false,
          status: 3, // 1/定位成功 2/取消定位 3/取消定位，关闭弹窗
          message: '取消定位，关闭弹窗',
          data: '',
        };
        let result = Object.assign(default_data, opt);
        this.$emit('on-change', result)
      },
      /**
       * 页面准备完毕
       */
      pageReadyFun() {
        this.$nextTick(function () {
          // 代码保证 this.$el 在 document 中
          // 初始化地图
          this.initMapFun();
        })
      },
      /**
       * 初始化地图
       */
      initMapFun(map, keywords) {
        let _this = this;
        keywords = keywords || this.init_serach;
        console.log('BMap == ', BMap)
        // 百度地图API功能
        this.map = new BMap.Map("allmap"); // 创建Map实例
        map = map || this.map;
        console.log('初始化地图 map == ', map)

        // 获取定位
        this.getLocationFun(map).then((point) => {
          map.centerAndZoom(point, 15); // 初始化地图,用城市名设置地图中心点
          map.enableScrollWheelZoom(); // 启用滚轮放大缩小
          var marker = new BMap.Marker(point); // 创建标注
          map.addOverlay(marker); // 将标注添加到地图中
          marker.setAnimation(BMAP_ANIMATION_BOUNCE); // 跳动的动画
          // 搜索
          this.serachFun(this.map, keywords);
        });

        this.is_loading = true; // 装载完成
      },
      /**
       * 获取定位
       */
      getLocationFun(map) {
        map = map || this.map;
        let _this = this;
        return new Promise((resolve, reject) => {
          var geolocation = new BMap.Geolocation();
          geolocation.getCurrentPosition(function (res) {
            console.log('定位 res == ', res);
            if (res) {
              _this.location_info = res;
              this.disableSDKLocation();
              let bMap_status = this.getStatus();
              console.log('您的位置：bMap_status failed ' + bMap_status);
              console.log('BMAP_STATUS_SUCCESS ', BMAP_STATUS_SUCCESS);
              console.log('BMAP_STATUS_PERMISSION_DENIED ', BMAP_STATUS_PERMISSION_DENIED);
              console.log('BMAP_STATUS_TIMEOUT ', BMAP_STATUS_TIMEOUT);
              console.log('BMAP_STATUS_UNKNOWN_LOCATION ', BMAP_STATUS_UNKNOWN_LOCATION);
              switch (bMap_status) {
                case BMAP_STATUS_SUCCESS:
                  console.log('定位成功！')
                  console.log('您的位置：' + res.point.lng + ',' + res.point.lat);
                  break;
                case BMAP_STATUS_PERMISSION_DENIED:
                  console.log('没有权限，拒绝访问！')
                  break;
                case BMAP_STATUS_TIMEOUT:
                  console.log('请求超时！')
                  break;
                case BMAP_STATUS_UNKNOWN_LOCATION:
                  console.log('未知错误！')
                  break;
              }

              resolve(res.point)
            } else {
              _this.myMessage({
                message: '获取地图失败，请刷新页面从新获取！'
              })
            }
          });
        })
      },
      /**
       * 搜索
       */
      serachFun(map, keywords) {
        if (keywords.length <= 0) {
          this.myMessage({
            message: '请输入关键字'
          })
          return;
        }
        const _this = this;
        map = map || this.map;
        var local = new BMap.LocalSearch(map, {
          renderOptions: {
            map: map, // 显示的目标地图
            // panel: "r-result", // 查询结果显示面板id 使用: <div id='r-result'></div>
          },
          pageCapacity: 100, // 页容量，取值范围：1 - 100
          onSearchComplete: function (res) { // 检索完成后的回调函数
            console.log('检索完成 res == ', res)
            console.log('检索完成 res.getCurrentNumPois() == ', res.getCurrentNumPois())
            console.log('检索完成 res.getPoi() == ', res.getPoi())
            console.log('检索完成 res.getPoi(0) == ', res.getPoi(0))
            // 判断状态是否正确
            if (local.getStatus() == BMAP_STATUS_SUCCESS) {
              var arr = [];
              for (var i = 0; i < res.getCurrentNumPois(); i++) {
                arr.push(res.getPoi(i));
              }
              console.log('检索完成 arr == ', arr)
              _this.serach_list = arr;
            } else {
              _this.myMessage({
                message: '没有找到相关的信息！'
              })
              // _this.serach_list = [];
            }
          }
        });
        local.search(keywords);
      },
      // 搜索位置
      runSerachFun(keywords) {
        keywords = keywords || '';
        let _this = this;

        // 搜索
        this.serachFun(this.map, keywords);

        // 初始化地图
        // this.initMapFun('', keywords);
      },
      /**
       * 搜索关键字列表
       */
      searchKeyListFun(e) {
        console.log('搜索关键字列表 == ', e)
      },
      /**
       * 点击搜索按钮
       */
      clickSerachBtnFun() {
        let mapKeywords = this.mapKeywords;
        console.log('点击搜索按钮 mapKeywords == ', mapKeywords);
        // 搜索位置
        this.runSerachFun(mapKeywords);
      },
      /**
       * 地址解析
       */
      geocoderFun(key) {
        console.log('地址解析 key == ', key)
        return new Promise((resolve, reject) => {
          let myGeo = new BMap.Geocoder();
          console.log('地址解析 myGeo == ', myGeo)
          myGeo.getLocation(key, function (res) {
            console.log('地址解析 res == ', res)
            if (res) {
              resolve(res);
            } else {
              reject();
            }
          })
        })
      },
      /**
       * 选择地址
       */
      selectedlocationFun(index) {
        console.log('选择地址 index == ', index)
        console.log('选择地址 this.serach_list == ', this.serach_list)
        let local_info = this.serach_list[index];
        console.log('选择地址 local_info == ', local_info)

        let {
          title,
          province,
          city,
          district,
          address,
          point,
        } = local_info;

        let new_local_info = deepClone({
          title,
          province,
          city,
          district,
          address,
          point,
        })

        console.log('选择地址 point == ', point)
        console.log('选择地址 new_local_info == ', new_local_info)

        // 地址解析
        this.geocoderFun(point).then((res) => {
          let {
            address,
            addressComponents
          } = res;
          let {
            city,
            district,
            province,
          } = addressComponents;

          let result = deepClone(new_local_info);
          result.district = district;

          let isNotProvince = address.indexOf(province) == -1,
            isNotCity = address.indexOf(city) == -1,
            isNotDistrict = address.indexOf(district) == -1;

          // 拼接-省-市-区
          let nweAddress =
            `${ isNotProvince ? province : ''}${ isNotCity ? city : ''}${ isNotDistrict ? district : ''}${result.address}`;
          console.log('选择地址 nweAddress == ', nweAddress)
          result.address = nweAddress;
          console.log('选择地址 result == ', result)

          // 告知弹窗变化
          this.informChangeFun({
            status: 1,
            message: '定位成功',
            data: result,
          });
        }).catch((err) => {
          // 告知弹窗变化
          this.informChangeFun({
            status: 2,
            message: '获取定位失败！',
            data: {},
          });
        });

        // 告知弹窗变化
        // this.informChangeFun({
        //   status: 1,
        //   message: '定位成功',
        //   data: local_info,
        // });

      }
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {


    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .dialog-container {
    box-sizing: border-box;
  }

  .map-body {
    display: flex;

    .map-main {
      width: 460px;
      height: 460px;
    }

    .map-control {
      flex: 1;
      min-width: 0;
      margin-left: 10px;

      .map-serach {
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;

        .serach-inp {}

        .serach-btn {
          flex: 1;
          min-width: 0;
          margin-left: 20px;

          &::v-deep {
            .fake-btn {
              // width: 100%;
              // height: 32px;
              // padding: 0;
              // font-size: 14px;
              // color: $common-number1-color;
              // border: 1px solid $common-number1-color;
              // background: #fff;
              outline: none;

              &.el-button:hover,
              &.el-button:focus,
              &.el-button:active {
                color: #fff;
                background: #66b1ff;
                border-color: #66b1ff;
              }

              &.el-button:active {
                color: $active-default-color;
                border-color: $active-default-color;
              }
            }
          }
        }
      }

      .serach-place {
        .serach-list {
          box-sizing: border-box;
          height: 418px;
          overflow-y: auto;
          overflow-x: hidden;

          .cl-item {
            box-sizing: border-box;
            padding: 10px;

            &:hover {
              background: #f5f5f5;
            }

            .cl-info {

              .cl-title,
              .cl-description {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .cl-title {
                font-weight: 500;
                font-size: 16px;
                color: #444443;
              }

              .cl-description {
                margin-top: 4px;
                font-size: 14px;
                color: #888;

              }
            }
          }
        }
      }
    }
  }

</style>
